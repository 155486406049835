import { computePosition, flip, offset, arrow, autoUpdate, Placement } from "@floating-ui/dom";
import uniqueId from "lodash/uniqueId";
import { Children, cloneElement, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Arrow, Container } from "./styled";
import { CSSTransition } from "react-transition-group";

interface Position {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

const positionReset: Position = {
  top: "",
  bottom: "",
  left: "",
  right: "",
};

function updateTooltipPosition({
  childRef,
  tooltipRef,
  arrowRef,
  placement,
}: {
  childRef: React.RefObject<HTMLElement>;
  tooltipRef: React.RefObject<HTMLDivElement>;
  arrowRef: React.RefObject<HTMLDivElement>;
  placement: Placement;
}) {
  const childEl = childRef.current;
  const tooltipEl = tooltipRef.current;
  const arrowEl = arrowRef.current;

  if (!childEl || !tooltipEl || !arrowEl) return;

  computePosition(childEl, tooltipEl, {
    placement,
    middleware: [
      offset(6), // offsets tooltip from refernce element
      flip(), // finds a placement that will fit (eg. flip "top" to "bottom")
      arrow({ element: arrowEl }),
    ],
  }).then(({ x, y, placement, middlewareData }) => {
    // position the tooltip DOM element
    Object.assign(tooltipEl.style, {
      left: `${x}px`,
      top: `${y}px`,
    });

    // position the arrow DOM element
    const arrowData = middlewareData.arrow;

    const staticSide = {
      top: "bottom",
      right: "left",
      bottom: "top",
      left: "right",
    }[placement.split("-")[0]] as string;

    const arrowPosition: Position = {};
    if (arrowData?.x) arrowPosition.left = `${arrowData.x}px`;
    if (arrowData?.y) arrowPosition.top = `${arrowData.y}px`;

    const arrowStyle = {
      ...positionReset,
      ...arrowPosition, // centering
      [staticSide]: "-4px", // positioning outside of tooltip
    };

    Object.assign(arrowEl.style, arrowStyle);
  });
}

interface TooltipProps {
  content: ReactNode;
  children: ReactElement;
  disableOnHover?: boolean;
  isTooltipOpen?: boolean;
  hideArrow?: boolean;
  className?: string;
  enterDelay?: number;
  exitDelay?: number;
  placement?: Placement;
  animationTimeout?: number;
}

const Tooltip = ({
  children,
  content,
  disableOnHover,
  isTooltipOpen = false,
  hideArrow,
  className,
  enterDelay,
  exitDelay,
  animationTimeout = 100,
  placement = "top",
}: TooltipProps) => {
  const [isTooltipShowing, setIsTooltipShowing] = useState(isTooltipOpen ?? false);

  const childRef = useRef<HTMLElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const enterTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const exitTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const idRef = useRef(uniqueId("tooltip-"));

  useEffect(() => {
    updateTooltipPosition({ childRef, tooltipRef, arrowRef, placement });
  }, [isTooltipShowing, placement]);

  useEffect(() => {
    setIsTooltipShowing(isTooltipOpen);
  }, [isTooltipOpen]);

  const showTooltip = useCallback(() => {
    clearTimeout(exitTimeoutRef.current);
    if (enterDelay) {
      enterTimeoutRef.current = setTimeout(() => {
        setIsTooltipShowing(true);
      }, enterDelay);
    } else {
      setIsTooltipShowing(true);
    }
  }, [enterDelay]);

  const hideTooltip = useCallback(() => {
    clearTimeout(enterTimeoutRef.current);
    if (exitDelay) {
      exitTimeoutRef.current = setTimeout(() => {
        setIsTooltipShowing(false);
      }, exitDelay);
    } else {
      setIsTooltipShowing(false);
    }
  }, [exitDelay]);

  // updates position when scrolling or resizing
  useEffect(() => {
    if (!childRef.current || !tooltipRef.current) return;
    autoUpdate(childRef.current, tooltipRef.current, () => {
      updateTooltipPosition({ childRef, tooltipRef, arrowRef, placement });
    });

    if (isTooltipShowing) {
      showTooltip();
    } else {
      hideTooltip();
    }
  }, [hideTooltip, isTooltipShowing, placement, showTooltip]);

  const onHoverEnter = () => {
    if (disableOnHover) return;
    showTooltip();
  };

  const onHoverLeave = () => {
    if (disableOnHover) return;
    hideTooltip();
  };

  return (
    <>
      {cloneElement(Children.only(children), {
        ref: childRef,
        onMouseEnter: onHoverEnter,
        onMouseLeave: onHoverLeave,
        onFocus: onHoverEnter,
        onBlur: onHoverLeave,
        "aria-describedby": idRef.current,
        ...children.props,
      })}
      <CSSTransition
        in={isTooltipShowing}
        nodeRef={tooltipRef}
        timeout={animationTimeout}
        classNames="fade"
        mountOnEnter
        unmountOnExit
      >
        <Container ref={tooltipRef} role="tooltip" className={className} animationTimeout={animationTimeout}>
          {content}
          <Arrow
            ref={arrowRef}
            role="tooltip-arrow"
            style={{ display: hideArrow ? "none" : "unset" }}
            className={className}
          />
        </Container>
      </CSSTransition>
    </>
  );
};

export { Tooltip };
