import { z } from "zod";

export const RewatchSchema = z.object({
  id: z.string(),
  src: z.string(),
  startTime: z.string(),
  ingestDelay: z.coerce.number(),
});

export type Rewatch = z.infer<typeof RewatchSchema>;
