import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useAuthModal } from "../../../auth/AuthModalProvider";
import { useAuth } from "../../../auth/AuthProvider/hooks/useAuth";
import { modalTransitionTime } from "../../../styles/constants";
import { AuthModals } from "./components/AuthModals";

const AuthModalsContainer = () => {
  const { login, register, resetPasswordRequest, resendVerificationEmail } = useAuth();

  const authModal = useAuthModal();

  const nodeRef = useRef(null);

  return (
    <CSSTransition
      in={authModal?.isModalOpen}
      timeout={modalTransitionTime}
      nodeRef={nodeRef}
      classNames="fade"
      mountOnEnter
      unmountOnExit
    >
      <AuthModals
        ref={nodeRef}
        modalState={authModal?.modalState}
        closeModal={authModal?.closeModal}
        login={login}
        register={register}
        resetPasswordRequest={resetPasswordRequest}
        resendVerificationEmail={resendVerificationEmail}
      />
    </CSSTransition>
  );
};

export { AuthModalsContainer };
