import { useContext } from "react";
import { ChangeAvatarModalContext } from "./index";

const useAvatarModal = () => {
  const context = useContext(ChangeAvatarModalContext);

  if (context === undefined) {
    throw new Error("useAvatarModal must be used within a AvatarModalProvider");
  }
  return context;
};

export { useAvatarModal };
