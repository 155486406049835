import { NavbarChildItem } from "@data/sanity/getSettings/schemas/NavbarSchema";
import { isSsrPage } from "../../../../../ssrPagesRoutes/isSsrPage";
import { environment, ssrSiteUrl } from "../../../../config";
import { Link } from "react-router-dom";
import { forwardRef, ReactNode, Ref } from "react";

type NavProps = Pick<NavbarChildItem, "externalLink" | "route" | "title">;

const getLinkUrl = (navbarItem: NavProps): string => {
  if (navbarItem.externalLink) {
    return navbarItem.externalLink.startsWith("/")
      ? navbarItem.externalLink
      : new URL(navbarItem.externalLink).pathname;
  } else {
    return navbarItem.route ?? "#";
  }
};

export const NavLink = forwardRef(
  (
    {
      navbarItem,
      children,
      className,
      onClick,
    }: {
      navbarItem: NavProps;
      className?: string;
      children?: ReactNode;
      onClick?: () => void;
    },
    ref: Ref<HTMLAnchorElement>,
  ) => {
    if (navbarItem.externalLink) {
      // External link: open in new tab
      return (
        <a
          href={navbarItem.externalLink}
          target="_blank"
          rel="noreferrer"
          className={className}
          ref={ref}
          onClick={onClick}
        >
          {children}
        </a>
      );
    }

    if (isSsrPage({ pathname: navbarItem.route, environment: environment })) {
      // Link to SSR site
      return (
        <a href={`${ssrSiteUrl}${navbarItem.route}`} className={className} ref={ref} onClick={onClick}>
          {children}
        </a>
      );
    }

    return (
      // Internal link
      <Link to={getLinkUrl(navbarItem)} className={className} ref={ref} onClick={onClick}>
        {children}
      </Link>
    );
  },
);

NavLink.displayName = "NavLink";
