import { AxiosInstance } from "axios";

import { UserWalletSchema } from "../../shared/hooks/useUserWallet/schemas";
import { PointsProductResponseSchema, PurchaseRequestBody } from "./schemas";
import { PointsPurchaseResponseSchema } from "./types/pointsPurchases";
import { PointsCollectionsResponseSchema } from "./types/pointsCollections";

export class PointsApiClient {
  public constructor(private readonly client: AxiosInstance) {}
  public getUserWallet = async (userId: string) => {
    const { data } = await this.client.get(`/v1/points/wallets/${userId}`);

    return UserWalletSchema.parse(data);
  };

  public getProducts = async () => {
    const { data } = await this.client.get("/v1/points/products");

    return PointsProductResponseSchema.parse(data);
  };

  public purchaseProduct = async (id: string, body: PurchaseRequestBody) => {
    const { data } = await this.client.post(`/v1/points/products/${id}/purchase`, body);

    return PointsPurchaseResponseSchema.parse(data);
  };

  public getCollections = async () => {
    const { data } = await this.client.get("/v1/points/collections/blast");

    return PointsCollectionsResponseSchema.parse(data);
  };

  public getPointsPurchases = async () => {
    const { data } = await this.client.get("/v1/points/purchases");

    return PointsPurchaseResponseSchema.parse(data);
  };
}
