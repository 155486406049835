import { useState } from "react";
import { useAuthModal } from "../../../auth/AuthModalProvider";

export function useFirstLoginCheck() {
  const authModal = useAuthModal();
  const [isFirstLoginTriggered, setIsFirstLoginTriggered] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.get("first-login") !== null && !isFirstLoginTriggered) {
    setIsFirstLoginTriggered(true);
    authModal?.openModal("first-login");
  }
}
