import { TeeVeeState } from "../TeeVeeAnimation";
import { AuthModalState } from "../../../../../auth/AuthModalProvider";

export const getTeeVeeState = (state: AuthModalState): TeeVeeState | undefined => {
  switch (state) {
    case "register":
      return "roll-in";

    case "awaiting-verification-email":
      return "verify";

    default:
      return undefined;
  }
};

export const getHeaderText = (state: AuthModalState): string => {
  switch (state) {
    case "login":
      return "Log in to your account";
    case "first-login":
      return "Thanks for signing up! Please log in to complete your profile!";
    case "register":
      return "Join the blast.tv community";
    case "forgot-password":
    case "reset-password-requested":
      return "Forgot your password?";
    case "awaiting-verification-email":
    case "verification-email-resent":
      return "Verify your email";
    case "ban":
      return "THIS ACCOUNT IS PERMANENTLY BANNED.";
    default:
      return "";
  }
};

export const getSubmitButtonText = (state: AuthModalState): string => {
  switch (state) {
    case "login":
    case "first-login":
      return "Log in";
    case "register":
      return "Join the community";
    case "forgot-password":
      return "Send Link";
    case "ban":
      return "Okay";
    default:
      return "";
  }
};

export const getDescriptionLinkText = (state: AuthModalState): string => {
  switch (state) {
    case "login":
      return "Join the community";

    case "register":
      return "Log in";

    case "reset-password-requested":
      return "Resend";

    case "awaiting-verification-email":
      return "Resend";

    default:
      return "";
  }
};

export const getDescriptionText = (state: AuthModalState): string => {
  switch (state) {
    case "login":
      return "Don't have an account yet?";

    case "register":
      return "Already a BLAST.tv member?";

    case "reset-password-requested":
    case "awaiting-verification-email":
      return "Didn't receive an email and checked your spam?";

    case "verification-email-resent":
      return "We've sent you an email with the link again.";

    case "ban":
      return "Your user has been permanently banned due to violation of our Terms and Conditions/Community Guidelines. You are no longer able to use your account with Blast.tv.";

    default:
      return "";
  }
};

export const getCopyTextSpan = (state: AuthModalState): JSX.Element | null => {
  switch (state) {
    case "forgot-password":
      return (
        <span>
          {`Don't worry! Even the best of us forget. Get a link to reset your
            password by entering the email address you used when registering on
            BLAST.tv.`}
        </span>
      );

    case "reset-password-requested":
      return (
        <span>
          {`Thanks! If the email address is registered on BLAST.tv, you'll receive an email with a link to reset your password shortly.`}
        </span>
      );

    case "awaiting-verification-email":
      return (
        <span>
          {`We've sent a verification email to you. You know the drill - click the link in the email to verify your account and start enjoying BLAST.tv asap!`}
        </span>
      );

    case "verification-email-resent":
      return (
        <span>
          {`We've sent a verification email to you. You know the drill - click the link in the email to verify your account and start enjoying BLAST.tv asap!`}
        </span>
      );

    case "ban":
      return <span>If you have questions, contact us at support@blast.tv</span>;

    default:
      return null;
  }
};

export const getFederatedLoginErrorMessage = (error: string): string => {
  switch (error) {
    case "email-already-exists":
      return "You're already a BLAST.tv member, please try and login using the same method you registered with.";

    case "email-domain-not-allowed":
      return "This email domain is not allowed.";

    case "user-cancelled-authorize":
      return "You seem to have cancelled the authentication process. Please try again.";

    default:
      return "An unknown error has occurred, please try again. If this continues, please email support@blast.tv.";
  }
};
