import axios from "axios";
import { apiBaseURL } from "../../config";
import { loggingInterceptor } from "./interceptors/loggingInterceptor";
import { authInterceptor } from "../../auth/authInterceptor";

const blastApi = axios.create({
  baseURL: apiBaseURL,
});

blastApi.interceptors.request.use(authInterceptor);
blastApi.interceptors.response.use((response) => response, loggingInterceptor);

export { blastApi };
