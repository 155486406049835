import axios from "axios";
import { baseAssetsURL } from "../../config";
import { loggingInterceptor } from "./interceptors/loggingInterceptor";

const blastAssetsApi = axios.create({
  baseURL: baseAssetsURL,
});

blastAssetsApi.interceptors.response.use((response) => response, loggingInterceptor);

export { blastAssetsApi };
