import { AxiosError, AxiosInstance } from "axios";
import { camelCaseKeys } from "../shared/camelCaseKeys";
import { Rewatch, RewatchSchema } from "./schemas";

export class VideoApiClient {
  constructor(private readonly client: AxiosInstance) {}

  getRewatchById = async (id: string): Promise<Rewatch | null> => {
    try {
      const { data } = await this.client.get(`/v1/videos/rewatch/${id}`);

      return RewatchSchema.parse(camelCaseKeys(data));
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 404) {
        return null;
      }

      throw error;
    }
  };
}
