import { breakpoints } from "@shared/helpers/useBreakpoints";
export const framedContentVerticalSpacing = "1.5rem";
export const framedContentHorizontalSpacing = "1.5rem";
export const framedContentVerticalSpacingMobile = "0.75rem";
export const framedContentHorizontalSpacingMobile = "0.75rem";

export const maxWidthContentFrame = 1480;
export const maxWidthMobile = `${breakpoints.tablet - 1}px`;

export const navigationHeightPx = 60;
export const navigationHeightMobilePx = 44;

// Transition times
export const modalTransitionTime = 300;
export const SHORT_ANIMATION_TIMEOUT = 300;

// Animation durations
export const FADE_DRAWER_ANIMATIONS_OVERLAP = 100;

// Live CTA Banner
export const liveCTABannerHeight = 24;
export const liveCTABannerHeightMobile = 42;
