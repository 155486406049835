import { useIngestionApi } from "../../../api/ApiProvider/hooks";
import { useMutation } from "@tanstack/react-query";

function usePostPage() {
  const ingestionApi = useIngestionApi();
  return useMutation({
    mutationFn: ingestionApi.postPage,
  });
}

export { usePostPage };
