import { blastApi } from "../../../apiClients/blastApi";
import { Series } from "../shared/types/Series";
import { MatchV2Schema, mapMatchV2ToSeries } from "../shared/types/v2/match";
import { captureException } from "@sentry/browser";

export const getSeriesById = async (seriesId: string): Promise<Series> => {
  const { data } = await blastApi.get(`v2/matches/${seriesId}/detailed`);

  try {
    // Convert v2 response to the old schema
    // TODO: Remove the old schemas and replace them with new models throughout the code base
    const matchV2 = MatchV2Schema.parse(data);
    return mapMatchV2ToSeries(matchV2);
  } catch (error) {
    captureException(`Parsing series data failed`, {
      extra: {
        seriesId,
        error,
      },
    });

    throw error;
  }
};
