import styled from "styled-components";

export const Container = styled.div<{
  isCollapsed: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ isCollapsed }) => (isCollapsed ? "0" : "128px")};
  transition: all 0.3s ease-in-out;
`;

export const Video = styled.video`
  margin: 0 auto;
`;
