import { Icon } from "../../../../shared/components/Icon";

interface PointsNotificationProps {
  amount: number;
  title: string;
  message: string;
}

function PointsNotification({ amount, title, message }: PointsNotificationProps) {
  return (
    <div className="pointer-events-auto w-full overflow-hidden rounded bg-canvas-90 shadow-lg ring-1 ring-canvas-80/50">
      <div className="flex justify-between bg-gradient-to-l">
        <div className="flex size-full items-center justify-between bg-canvas-100/40 p-2">
          <span className="flex items-center justify-center gap-x-2 text-yellow font-style-label-2">
            <Icon icon="blastCoin" className="text-yellow" />+{amount}
          </span>
          <span className="text-yellow font-style-b3-body-copy">{title}</span>
        </div>
      </div>
      <div className="p-2">
        <div className="flex items-center justify-center">
          <div className="flex-1">
            <span className="text-neutral-20 font-style-b3-body-copy">
              You received {amount} points{" "}
              {message?.toLowerCase()?.includes("refund") ? "from getting a refund." : `for ${message}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export { PointsNotification };
