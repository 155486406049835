const production = [
  "/article*",
  "/cs/news",
  "/cs/news/*",
  "/dota/news",
  "/dota/news/*",
  "/rl/news*",
  "/quiz/*",
  "/gaming*",
  "/cs-quiz",
];

export const ssrRoutesPatterns = {
  production,
  development: [...production],
  local: [...production],
};
