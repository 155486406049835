import { z } from "zod";
import { PlayerCategorySchema } from "../../routes/PlayerPickemsPage/schema";

const PostPlayerPicksPayloadSchema = z.array(
  z.object({
    playerCategory: PlayerCategorySchema,
    playerId: z.string(),
    playerName: z.string(),
    isCaptain: z.boolean().optional(),
  }),
);

export type PostPlayerPicksPayload = z.infer<typeof PostPlayerPicksPayloadSchema>;

const PlayerPickemsMatchScoreSchema = z.object({
  matchId: z.string(),
  rank: z.number(),
  didWin: z.boolean(),
  players: z.array(
    z.object({
      playerId: z.string(),
      rank: z.number(),
      didWin: z.boolean(),
    }),
  ),
});

export const UserPlayerPicksSchema = z.object({
  playerCategory: PlayerCategorySchema,
  playerId: z.string(),
  playerName: z.string(),
  isCaptain: z.boolean(),
  pickemsId: z.string(),
  userId: z.string(),
  score: z.number(),
  matchScores: PlayerPickemsMatchScoreSchema.array(),
});

export type UserPlayerPicks = z.infer<typeof UserPlayerPicksSchema>;
