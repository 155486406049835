import { useCallback, useMemo, useState } from "react";
import { ProfilePopup } from "./components/ProfilePopup";
import { useUserProfile } from "../../../../../auth/useUserProfile";
import { useBreakpoints } from "@shared/helpers/useBreakpoints";
import { useNavigate } from "react-router-dom";
import { generateImageUrl } from "@data/assets/helpers/generateImageUrl";
import { generateAssetsId } from "@data/assets/helpers/generateAssetsId";
import { Icon } from "@shared/components/Icon";

const ProfileButton = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const userProfile = useUserProfile();
  const { isSmallDesktop } = useBreakpoints();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (isSmallDesktop) {
      // toggle modal on desktop
      setIsPopupOpen((current) => !current);
    } else {
      // redirect to profile page on mobile
      navigate("/profile");
    }
  }, [isSmallDesktop, navigate]);

  const avatarSrc = useMemo(() => {
    if (!userProfile?.avatarId) return undefined;
    return generateImageUrl("avatars", generateAssetsId(userProfile.avatarId, "3d"), {
      format: "auto",
      height: "200",
    });
  }, [userProfile]);

  return (
    <div className="relative">
      <div className="flex items-center gap-3">
        <button
          aria-label="Profile"
          onClick={handleClick}
          className="size-9 rounded-full border border-solid border-white max-sd:size-8"
        >
          {avatarSrc ? (
            <img alt="avatar" src={avatarSrc} id={userProfile?.avatarId} />
          ) : (
            <Icon icon="anonUser" className="m-auto" />
          )}
        </button>
      </div>
      <ProfilePopup
        isOpen={isPopupOpen}
        avatarSrc={avatarSrc}
        username={userProfile?.username}
        onClose={() => setIsPopupOpen(false)}
        className="max-sd:hidden"
      />
    </div>
  );
};

export { ProfileButton };
