import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/react/24/outline";

interface AlertNotificationProps {
  type: "success" | "error" | "info";
  message: string;
}

export function AlertNotification({ type, message }: AlertNotificationProps) {
  return (
    <div className="pointer-events-auto w-full overflow-hidden rounded bg-canvas-90 shadow-lg ring-1 ring-neutral-50/25">
      <div className="p-4">
        <div className="flex items-center justify-center">
          {type === "success" && <CheckCircleIcon className="size-5 text-green" aria-hidden="true" />}
          {type === "error" && <ExclamationCircleIcon className="size-5 text-red" aria-hidden="true" />}
          {type === "info" && <InformationCircleIcon className="size-5 text-yellow" aria-hidden="true" />}
          <div className="ml-3 w-0 flex-1">
            <span className="font-style-b4-body-copy">{message}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
