import { ReactNode, createContext, useState } from "react";

interface TheaterModeValue {
  isTheaterMode: boolean;
  toggleTheaterMode: () => void;
}

export const TheaterModeContext = createContext<TheaterModeValue | undefined>(undefined);

const useTheaterModeValue = (): TheaterModeValue => {
  const [isTheaterMode, setIsTheaterMode] = useState(false);

  const toggleTheaterMode = () => {
    setIsTheaterMode((prev) => !prev);
  };

  return {
    isTheaterMode,
    toggleTheaterMode,
  };
};

const TheaterModeProvider = ({ children }: { children: ReactNode }) => {
  const theaterModeProviderValue = useTheaterModeValue();

  return <TheaterModeContext.Provider value={theaterModeProviderValue}>{children}</TheaterModeContext.Provider>;
};

export { TheaterModeProvider };
