import { useFetchCurrentUserProfile } from "../AuthProvider/hooks/useFetchCurrentUserProfile";
import { useAuth } from "../AuthProvider/hooks/useAuth";

function useUserProfile() {
  const { userId } = useAuth();

  const { data: userProfile } = useFetchCurrentUserProfile({
    userId,
  });

  return userProfile;
}

export { useUserProfile };
