import { captureException } from "@sentry/browser";
import { client } from "../../../apiClients/sanity";
import { Settings, SettingsSchema } from "./schemas/SettingsSchema";
import { NavbarTopLevelItem, NavbarSchema } from "./schemas/NavbarSchema";
import { GameId } from "@providers/GameIdProvider";

const buildNavbarQuery = ({ gameId }: { gameId: GameId }) => {
  return `
      *[_type in ["navbar"] && gameSection == "/${gameId}"] {
        ...,
        'items': items[hidden != true]{
          ...,
          'children': children[hidden != true]{
            ...,
            'route':coalesce(contentLink->route, route)
          },
          'route':coalesce(contentLink->route, route)
        }
      }[0]`;
};

export const buildQuery = () => {
  return `
{
  "settings": *[_type in ["settings"]] {
    ...,
  }[0],
  "nav": {
    "cs": ${buildNavbarQuery({ gameId: "cs" })},
    "dota": ${buildNavbarQuery({ gameId: "dota" })},
    "supercell": ${buildNavbarQuery({ gameId: "supercell" })},
  }
}`;
};

export const getSettings = async () => {
  const data = await client.fetch(buildQuery()).catch((error) => {
    throw new Error("failed to fetch CMS data", { cause: error });
  });

  const content: {
    nav: {
      [key in GameId]: NavbarTopLevelItem[];
    };
    settings?: Settings;
  } = {
    nav: {
      cs: [],
      dota: [],
      supercell: [],
      gaming: [],
    },
  };

  const settingsParsed = SettingsSchema.safeParse(data["settings"]);
  if (settingsParsed.success) {
    content.settings = settingsParsed.data;
  } else {
    // eslint-disable-next-line no-console
    console.error(`failed to find schema for settings`, { cause: settingsParsed.error });
    captureException(settingsParsed.error);
  }

  const gameIds = ["cs", "dota", "supercell"] as const;
  for (const gameId of gameIds) {
    const parsed = NavbarSchema.safeParse(data.nav[gameId]);
    if (parsed.success) {
      content.nav[gameId] = parsed.data.items;
    } else {
      // eslint-disable-next-line no-console
      console.error(`failed to parse nav data`, { cause: parsed.error });
      captureException(parsed.error);
    }
  }

  return content;
};
