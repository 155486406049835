import { AxiosInstance } from "axios";
import {
  LeaderboardRankingResponse,
  LeadeboardResponseSchema,
  Tournament,
  TournamentSchema,
  RankHistories,
  RankHistoriesSchema,
} from "./types";

export class LeaderboardDataBucketApiClient {
  public constructor(private readonly client: AxiosInstance) {}

  public getLeaderboardRankings = async (date: string): Promise<LeaderboardRankingResponse> => {
    const { data } = await this.client.get(`/valve-standings/${date}.json`);

    const result = LeadeboardResponseSchema.safeParse(data);

    if (!result.success) {
      // eslint-disable-next-line no-console
      console.error(result.error);
      throw new Error(result.error.message);
    }

    return data;
  };

  public getRankingHistories = async (): Promise<RankHistories> => {
    const { data } = await this.client.get(`/valve-standings/histories.json`);

    const result = RankHistoriesSchema.safeParse(data);

    if (!result.success) {
      // eslint-disable-next-line no-console
      console.error(result.error);
      throw new Error(result.error.message);
    }

    return data;
  };

  public getUpcomingTournaments = async (): Promise<Tournament[]> => {
    const { data } = await this.client.get(`/leaderboard/tournaments.json`);

    const currentDate = new Date();

    data.filter((tournament: Tournament) => {
      const startDate = new Date(tournament.startDate);
      return startDate > currentDate;
    });

    const result = TournamentSchema.array().safeParse(data);

    if (!result.success) {
      // eslint-disable-next-line no-console
      console.error(result.error);
      throw new Error(result.error.message);
    }

    return data;
  };

  public getPastTournaments = async (): Promise<Tournament[]> => {
    const { data } = await this.client.get(`/leaderboard/past_tournaments.json`);

    const result = TournamentSchema.array().safeParse(data);

    if (!result.success) {
      // eslint-disable-next-line no-console
      console.error(result.error);
      throw new Error(result.error.message);
    }

    return data;
  };
}
