import { DependencyList, EffectCallback, useEffect } from "react";
import { useRef } from "react";

/**
 * @deprecated - Don't use this hook, it's an anti-pattern.
 * Custom hook for determining if the component is rendering for the first time.
 * @returns {boolean} A boolean value indicating whether the component is rendering for the first time.
 * @see [Documentation](https://usehooks-ts.com/react-hook/use-is-first-render)
 * @example
 * const isFirstRender = useIsFirstRender();
 * // Use isFirstRender to conditionally execute code only on the initial render.
 */
function useIsFirstRender(): boolean {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

/**
 * @deprecated - Don't use this hook, it's an anti-pattern.
 * Custom hook that runs an effect only on updates (not on the initial render).
 * @param {EffectCallback} effect - The function to run as the effect.
 * @param {DependencyList} [deps] - An optional array of dependencies for the effect.
 * @see [Documentation](https://usehooks-ts.com/react-hook/use-update-effect)
 * @example
 * // Usage of useUpdateEffect hook
 * useUpdateEffect(() => {
 *   // Effect code to run on updates
 *   console.log('Component updated!');
 * }, [dependency1, dependency2]);
 */
export function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const isFirst = useIsFirstRender();

  useEffect(() => {
    if (!isFirst) {
      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
