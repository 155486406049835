import { z } from "zod";
import { baseAssetsURL } from "../../../../config";

const imageOptionsSchema = z.object({
  width: z.string().optional(),
  height: z.string().optional(),
  format: z.literal("auto"),
});

type ImageOptions = z.infer<typeof imageOptionsSchema>;
type ImageCategory = "products" | "emojis" | "avatars" | "teams" | "players" | "triggers" | "dota2-hero" | "dota2-rank";

const getCategoryBasePath = (category: ImageCategory) => {
  switch (category) {
    case "dota2-hero":
      return "images/dota2/heroes";

    case "dota2-rank":
      return "images/dota2/rank-icons";

    default:
      return `images/${category}`;
  }
};

export const generateImageUrl = (category: ImageCategory, filename: string, options?: ImageOptions) => {
  if (!options) return `${baseAssetsURL}/images/${category}/${filename}`;

  const searchParams = new URLSearchParams(options);

  return `${baseAssetsURL}/${getCategoryBasePath(category)}/${filename}?${searchParams}`;
};
