import { AxiosError, AxiosInstance } from "axios";
import { LoginResult, RegisterResult } from "../../../auth/AuthProvider";
import { clearCookies } from "../../../auth/authInterceptor";
import {
  ForgotPasswordResetRequest,
  ForgotPasswordResetResult,
  LoginRequest,
  RequestResetPasswordRequest,
  SignupRequest,
} from "../types";
import { ChangeEmailRequest, ChangePasswordRequest, CheckPasswordRequest } from "./types";
import * as schemas from "./schemas";

export class AuthApiClient {
  public constructor(private readonly client: AxiosInstance) {}

  public login = (request: LoginRequest): Promise<LoginResult> => {
    let result: LoginResult;
    return this.client
      .post(
        "/v1/auth/login",
        {
          login: request.login,
          password: request.password,
        },
        {
          withCredentials: true,
        },
      )
      .then(() => {
        result = "success";
        return result;
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const code = error.response?.data?.code;
          if (code === "user-not-verified") {
            result = "not-verified";
            return result;
          } else if (code === "wrong-credentials") {
            result = "wrong-credentials";
            return result;
          }
        }
        throw error;
      });
  };

  public logout = async (): Promise<void> => {
    await this.client.post("/v1/auth/logout", undefined, { withCredentials: true }).finally(() => {
      clearCookies();
    });
  };

  public signup = ({ email, password, isOptIn }: SignupRequest): Promise<RegisterResult> => {
    let result: RegisterResult;
    return this.client
      .post(
        "/v1/auth/register",
        {
          email,
          password,
          newsSubscription: isOptIn,
        },
        { withCredentials: true },
      )
      .then(() => {
        result = "success";
        return result;
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const code = error.response?.data?.code;
          if (code === "email-already-taken") {
            result = "email-already-registered";
            return result;
          } else if (code === "invalid-password") {
            result = "invalid-password";
            return result;
          } else if (code === "invalid-email") {
            result = "invalid-email";
            return result;
          }
        }

        throw error;
      });
  };

  public requestResetPassword = ({ email }: RequestResetPasswordRequest): Promise<void> => {
    return this.client.post(`/v1/users/forgot-password`, { email }, { withCredentials: true });
  };

  public resendVerificationEmail = ({ email }: { email: string }): Promise<void> => {
    return this.client.post(
      `/v1/auth/resend`,
      { email, confirmationType: "REGISTER" },
      {
        withCredentials: true,
      },
    );
  };

  public forgotPasswordReset = async (request: ForgotPasswordResetRequest): Promise<ForgotPasswordResetResult> => {
    try {
      await this.client.post(`/v1/users/${request.userUuid}/forgot-password-reset`, {
        confirmationCode: request.confirmationCode,
        password: request.password,
      });

      return "success";
    } catch (error) {
      if (error instanceof AxiosError) {
        const code = error.response?.data?.code;
        if (code === "forgot-password-code-mismatch") {
          return "invalid-confirmation-code";
        } else if (code === "forgot-password-limit-exceeded") {
          return "limit-exceeded";
        } else if (code === "forgot-password-code-expired") {
          return "code-expired-or-used";
        }
      }

      throw error;
    }
  };

  public deleteUser = (userId: string): Promise<void> => {
    return this.client.delete(`/v1/users/${userId}`);
  };

  public changePassword = (request: ChangePasswordRequest): Promise<void> => {
    const parsedRequest = schemas.changePasswordRequest.parse(request);
    return this.client.post("/v1/users/change-password", parsedRequest, {
      withCredentials: true,
    });
  };

  public changeEmail = (request: ChangeEmailRequest): Promise<void> => {
    return this.client.post(
      "/v1/users/change-email",
      {
        newEmail: request.newEmail,
      },
      {
        withCredentials: true,
      },
    );
  };

  public checkPassword = (request: CheckPasswordRequest): Promise<boolean> => {
    return this.client
      .post(
        "/v1/auth/check",
        {
          password: request.password,
        },
        {
          withCredentials: true,
        },
      )
      .then(() => {
        return true;
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const code = error.response?.data?.code;
          if (code === "wrong-credentials") {
            return false;
          }
        }
        throw error;
      });
  };
}
