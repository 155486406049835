import { useContext } from "react";
import { ApiContext, ApiProviderValue } from "..";
import { ApiService } from "../../ApiService";
function useApiContext(): ApiProviderValue {
  const apiContext = useContext(ApiContext);
  if (apiContext === undefined) {
    throw Error("ApiContext was undefined. Are you attempting to use an API outside the ApiProvider scope?");
  }

  return apiContext;
}

function useApi<K extends keyof ApiService>(api: K): ApiService[K] {
  const apiContext = useApiContext();
  return apiContext.apiService[api];
}

export function useAuthApi() {
  return useApi("auth");
}

export function useVideoApi() {
  return useApi("video");
}

export function useChatApi() {
  return useApi("chat");
}

export function usePickemsApi() {
  return useApi("pickems");
}

export function usePointsApi() {
  return useApi("points");
}

export function useTriggersApi() {
  return useApi("triggers");
}

export function useFantasyDataBucketApi() {
  return useApi("fantasyDataBucket");
}

export function useDdsClient() {
  const apiContext = useApiContext();
  return apiContext.ddsClient;
}

export function useUsersApi() {
  return useApi("users");
}
export function useTournamentsDataBucketApi() {
  return useApi("tournamentsDataBucket");
}

export function useIngestionApi() {
  return useApi("ingestion");
}
