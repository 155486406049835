import styled, { css } from "styled-components";
import { toRem } from "@shared/helpers/toRem";

const text = css`
  font-family: TT Norms Pro;
  letter-spacing: 0em;
  font-weight: 700;
`;

// Desktop Variants
export const desktopSubHeading = css`
  //Figma: Desktop/D - Sub-Heading
  ${text}
  font-size: ${toRem(26)};
  line-height: ${32 / 26};
  text-transform: uppercase;
`;

export const desktopE1Eyebrow = css`
  //Figma: Desktop/D - E1 Eyebrow
  ${text}
  font-size: ${toRem(20)};
  line-height: ${24 / 20};
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const desktopE2Eyebrow = css`
  //Figma: Desktop/D - E2 Eyebrow
  ${text}
  font-size: ${toRem(14)};
  line-height: ${18 / 14};
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const desktopB1Body = css`
  //Figma: Desktop/D - B1 Body Text
  ${text}
  font-size: ${toRem(20)};
  line-height: ${30 / 20};
  font-weight: 500;
  letter-spacing: -0.02em;
`;

export const desktopB2Body = css`
  //Figma: Desktop/D - B2 Body Text
  ${text}
  font-size: ${toRem(16)};
  line-height: ${22 / 16};
  font-weight: 500;
  letter-spacing: -0.02em;
`;
export const desktopB3Body = css`
  //Figma: Desktop/D - B3 Body Text
  ${text}
  font-size: ${toRem(14)};
  line-height: ${16 / 12};
  font-weight: 500;
  letter-spacing: -0.01em;
`;

export const desktopB4Body = css`
  //Figma: Desktop/D - B4 Body Text
  ${text}
  font-size: ${toRem(12)};
  line-height: ${16 / 12};
  font-weight: 500;
  letter-spacing: -0.02em;
`;

export const desktopFormLabel = css`
  //Figma: Desktop/D - Form Label
  ${text}
  font-size: ${toRem(16)};
  letter-spacing: -0.02em;
`;

// Mobile Variants
export const mobileSubHeading = css`
  //Figma: Mobile/M - Sub-Heading
  ${text}
  font-size: ${toRem(18)};
  line-height: ${14 / 18};
  text-transform: uppercase;
`;

export const mobileE1Eyebrow = css`
  //Figma: Mobile/M - E1 Eyebrow
  ${text}
  font-size: ${toRem(16)};
  line-height: ${18 / 16};
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const mobileE2Eyebrow = css`
  //Figma: Mobile/M - E2 Eyebrow
  ${text}
  font-size: ${toRem(12)};
  line-height: ${14 / 12};
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;
export const mobileE3Eyebrow = css`
  //Figma: Mobile/M - E3 Eyebrow
  ${text}
  font-size: ${toRem(10)};
  line-height: ${12 / 10};
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

const mobileB1Body = css`
  //Figma: Mobile/M - B1 Body Text
  ${text}
  font-size: ${toRem(20)};
  line-height: ${28 / 20};
  font-weight: 500;
  letter-spacing: -0.02em;
`;

export const mobileB2Body = css`
  //Figma: Mobile/M - B2 Body Text
  ${text}
  font-size: ${toRem(16)};
  line-height: ${22 / 16};
  font-weight: 500;
  letter-spacing: -0.02em;
`;

export const mobileB3Body = css`
  //Figma: Mobile/M - B3 Body Text
  ${text}
  font-size: ${toRem(12)};
  line-height: ${16 / 12};
  font-weight: 500;
  letter-spacing: -0.02em;
`;

const mobileFormLabel = css`
  //Figma: Mobile/M - Form Label
  ${text}
  font-size: ${toRem(16)};
`;

const textStyles = {
  desktopSubHeading,
  desktopE1Eyebrow,
  desktopE2Eyebrow,
  desktopB1Body,
  desktopB2Body,
  desktopB3Body,
  desktopB4Body,
  desktopFormLabel,
  mobileSubHeading,
  mobileE1Eyebrow,
  mobileE2Eyebrow,
  mobileE3Eyebrow,
  mobileB1Body,
  mobileB2Body,
  mobileB3Body,
  mobileFormLabel,
};

export type TextTypography = keyof typeof textStyles;

export const StyledText = styled.span<{
  typography: TextTypography;
  $color?: string;
}>`
  ${(props) => textStyles[props.typography]}
  color: ${(props) => props.$color ?? "inherit"};
`;
