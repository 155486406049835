import { AxiosInstance } from "axios";
import { TournamentTimelineSchema } from "../../shared/components/HeaderHomePage/components/TournamentsTimeline/schemas";

export class TournamentsDataBucketApiClient {
  public constructor(private readonly dataBucketClient: AxiosInstance) {}

  queryKeys = {
    all: ["data-tournaments"] as const,
  };

  getTournamentsTimelineForHomePage = async () => {
    const dataUrl = `homepage/tournament-timeline.json`;

    const { data } = await this.dataBucketClient.get(dataUrl);
    if (!data) return undefined;

    try {
      return TournamentTimelineSchema.parse(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error); // helpful for debugging zod errors
      throw error;
    }
  };
}
