import { captureException } from "@sentry/browser";
import axios, { InternalAxiosRequestConfig } from "axios";
import cookies from "js-cookie";
import { apiBaseURL } from "../config";
import { DateTime } from "luxon";

export const getDomain = () => {
  return window.location.host.split(".").slice(-2).join(".");
};

export const clearCookies = () => {
  cookies.remove("access_token", {
    domain: `.${getDomain()}`,
  });
  cookies.remove("refresh_token", {
    domain: `.${getDomain()}`,
  });
  cookies.remove("id_token", {
    domain: `.${getDomain()}`,
  });
};

let updateRequest: null | Promise<unknown> = null;

async function updateTokensRequest(url: string) {
  try {
    const refreshToken = cookies.get("refresh_token");
    const idToken = cookies.get("id_token");
    if (!refreshToken || !idToken) {
      clearCookies();
      return;
    }

    await axios.post(
      url,
      {},
      {
        withCredentials: true,
      },
    );
  } catch (e) {
    clearCookies();
  }
}

export const updateTokens = (url: string) => {
  if (updateRequest) {
    return updateRequest;
  }

  updateRequest = new Promise<void>((resolve, reject) => {
    updateTokensRequest(url).then(() => {
      updateRequest = null;
      resolve();
    }, reject);
  });
  return updateRequest;
};

export const shouldRefreshToken = (
  accessToken: string | undefined,
  refreshToken: string | undefined,
  idToken: string | undefined,
): boolean => {
  if (!refreshToken || !idToken) {
    clearCookies();
    return false;
  }

  if (!accessToken) {
    return true;
  } else {
    try {
      const payload = JSON.parse(window.atob(decodeURIComponent(accessToken.split(".")[1])));
      // Give a 5 seconds buffer to make sure the token doesn't expire during the request
      if (DateTime.fromSeconds(payload.exp).minus({ seconds: 5 }) < DateTime.now()) {
        return true;
      }
    } catch (error) {
      // For some unknown reason we were unable to read the access token from cookies.
      // Clear the cookies to force a new login to get a valid access token.
      clearCookies();
      captureException(`Unable to read access_token from cookies. Clearing cookies. ${error}`);
    }
  }
  return false;
};

/**
  This interceptor will check for a valid access token and apply it as
  a bearer token for outgoing requests. If the access token has expired,
  the interceptor will make sure to refresh it before making the request.
  
  If the user has no access token, the request will be made without adding
  the access token to the request.
 * */
export const authInterceptor = async (config: InternalAxiosRequestConfig) => {
  const url = `${apiBaseURL}/v1/auth/token`;

  let accessToken = cookies.get("access_token");
  const refreshToken = cookies.get("refresh_token");
  const idToken = cookies.get("id_token");

  if (shouldRefreshToken(accessToken, refreshToken, idToken)) {
    await updateTokens(url);
    accessToken = cookies.get("access_token");
  }

  if (accessToken) {
    config.headers.set("Authorization", `Bearer ${accessToken}`);
  }

  return config;
};
