import { useQuery } from "@tanstack/react-query";
import { getGhostPage } from "../../../../api/data/sanity/getGhostPage";

export function useFetchGhostPage({ route }: { route?: string }) {
  return useQuery({
    queryKey: ["ghostPage", route],
    queryFn: () => (route ? getGhostPage(route) : undefined),
    enabled: !!route,
    retry: true, // Infinitely retry failing requests
  });
}
