import { getSettings } from ".";
import { queryKeys } from "../shared/queryKeys";
import { useQuery } from "@tanstack/react-query";

export const useGetSettings = () => {
  return useQuery({
    queryKey: queryKeys.settings(),
    queryFn: () => getSettings(),
    retry: true,
  });
};
