import { type ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useGetFlag } from "@data/flagsmith/getFlags/useGetFlag";
import { useAuth } from "../../auth/AuthProvider/hooks/useAuth";
import { LoadingPage } from "./LoadingPage";

const ProtectedRoute = ({
  children,
  requiredFlag,
  requiredAuth,
}: {
  children: ReactElement;
  requiredFlag?: string;
  requiredAuth: boolean;
}) => {
  const { isAuthenticated } = useAuth();
  const { enabled: isFlagEnabled, loading: isFlagLoading } = useGetFlag(requiredFlag ?? "");

  if (!!requiredFlag && isFlagLoading) {
    return <LoadingPage />;
  }

  if ((!isAuthenticated && requiredAuth) || (!!requiredFlag && !isFlagEnabled)) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export { ProtectedRoute };
