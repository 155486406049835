import styled from "styled-components";
import { colors } from "../../../designSystemLegacy/colors";
import { fontBody } from "../../../designSystemLegacy/fonts";
import { zIndex } from "../../../styles/zIndex";

export const Container = styled.div<{ animationTimeout: number }>`
  ${fontBody}
  color: ${colors.white};
  z-index: ${zIndex.tooltip};

  position: absolute;

  background-color: ${colors.blastPurple};
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  padding: 0.5rem;

  pointer-events: none;

  &.fade-enter {
    transform: translateY(0.5rem);
    opacity: 0;
  }

  &.fade-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all ${({ animationTimeout }) => animationTimeout}ms ease-in;
  }

  &.fade-exit {
    transform: translateY(0);
    opacity: 1;
  }

  &.fade-exit-active {
    transform: translateY(0.5rem);
    opacity: 0;
    transition: all ${({ animationTimeout }) => animationTimeout}ms ease-in;
  }
`;

export const Arrow = styled.div`
  position: absolute;
  background-color: ${colors.blastPurple};

  width: 0.5rem;
  height: 0.5rem;
  transform: rotate(45deg);
`;
