export const CloseIcon = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.6" cx="25" cy="25" r="25" transform="matrix(-1 0 0 1 50 0)" fill="#1F0C19" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.7015 17.1652C33.345 17.8088 33.3176 18.8796 32.6402 19.557L19.557 32.6402C18.8796 33.3176 17.8087 33.345 17.1652 32.7015C16.5217 32.058 16.5491 30.9871 17.2265 30.3097L30.3097 17.2266C30.9871 16.5491 32.0579 16.5217 32.7015 17.1652Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3214 17.2985C17.9649 16.655 19.0357 16.6824 19.7131 17.3598L32.7963 30.443C33.4737 31.1204 33.5012 32.1913 32.8576 32.8348C32.2141 33.4783 31.1433 33.4509 30.4659 32.7735L17.3827 19.6903C16.7053 19.0129 16.6778 17.9421 17.3214 17.2985Z"
      fill="white"
    />
  </svg>
);
