// colors from Blast TV Design System
// https://www.figma.com/file/4dk0hHJ3Kft3s9R08YWFD5/BLAST.tv-Design-System

const white = "#FFFFFF";
const black = "#000000";

// Primary
const blastYellow = "#FFFE3E";

// Secondary
const blastLiveRed = "#F23F37";
const blastPurple = "#9E4FF4";
const blastGreen = "#0CC76E";
const blastBlue = "#2EB8DC";
const blastOrange = "#FFC222";

// UI Elements
const yellowHover = "#FFFE72";
const yellowDisabled = "#5E5000";

// Neutral Surfaces
const blastCanvas = "#1F0C19";
const blastCardCanvas = "#251821";
const blastCanvas10 = "#362431";
const blastCanvas20 = "#4D3D48";
const blastCanvas50 = "#8D858C";
const blastCanvas60 = "#A49EA3";
const blastCanvas70 = "#BCB6BA";
const blastCanvas80 = "#D2CED1";
const blastCanvas90 = "#E9E7E8";

/**
 * @deprecated Use @blastorg/portal-pattern-library
 */
const colors = {
  white,
  black,
  blastYellow,
  blastCanvas,
  blastCardCanvas,
  blastLiveRed,
  blastPurple,
  blastGreen,
  blastBlue,
  blastOrange,
  blastCanvas10,
  blastCanvas20,
  blastCanvas50,
  blastCanvas60,
  blastCanvas70,
  blastCanvas80,
  blastCanvas90,
  yellowHover,
  yellowDisabled,

  // Other colors (Not in the Design System)
  blastPurpleLight: "#3F2938",
  blastGrayBackground: "#B8B8B8",
  grayLight: "#717172",
  blastPurpleBackground: "#3F2938",
  blastRed: "#F23F37",
};

export type Color = keyof typeof colors;

export { colors };
