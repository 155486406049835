import { z } from "zod";

export const TournamentSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string().nullable(),
  circuitId: z.string().nullish(),
  location: z.string().nullish(),
  prizePool: z.string().nullish(),
  numberOfTeams: z.coerce.number().nullish(),
  externalId: z.string().nullish(),
});
