import { Icon } from "@shared/components/Icon";
import { useUserWallet } from "@shared/hooks/useUserWallet";
import { Link } from "react-router-dom";

export const PointsButton = () => {
  const { data: wallet, isLoading } = useUserWallet();
  return (
    <Link
      to="/profile/redeem"
      className="flex items-center justify-between gap-2 rounded-full bg-canvas-90 px-2 py-1 transition-all duration-200 ease-in-out hover:bg-canvas-85"
    >
      <Icon icon="blastCoin" className="text-yellow" />
      {isLoading ? (
        <span className="block h-4 w-12 animate-pulse rounded-full bg-canvas-80" />
      ) : (
        <span className="text-white font-style-label-2">{wallet?.amount.toLocaleString()}</span>
      )}
    </Link>
  );
};
