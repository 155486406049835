import { z } from "zod";

const PlayerSchema = z.object({
  name: z.string(),
  country: z.string(),
  countryIso: z.string(),
});

const RankingsSchema = z.object({
  rank: z.number(),
  region: z.string(),
  points: z.number(),
  name: z.string(),
  prevDiff: z.number(),
  roster: z.array(PlayerSchema),
});

export const LeadeboardResponseSchema = z.object({
  date: z.string(),
  standings: z.array(RankingsSchema),
});

export type LeaderboardRankingResponse = z.infer<typeof LeadeboardResponseSchema>;

const RankHistoryItemSchema = z.object({
  date: z
    .string()
    .refine(
      (value) => {
        return !isNaN(Date.parse(value));
      },
      {
        message: "Invalid date format",
      },
    )
    .transform((value) => {
      return new Date(value);
    }),
  rank: z.number(),
  points: z.number(),
});

const RankHistorySchema = z.array(RankHistoryItemSchema);

export const RankHistoriesSchema = z.record(RankHistorySchema);

export type RankHistories = z.infer<typeof RankHistoriesSchema>;

export const TournamentSchema = z.object({
  id: z.string(),
  tier: z.string(),
  name: z.string(),
  prize: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  numberTeams: z.number().nullable(),
  eventLocation: z.string(),
  image: z.string(),
  participants: z.array(z.object({ name: z.string(), image: z.string().nullable() })),
  prizeDistribution: z
    .array(
      z.object({
        place: z.string(),
        prize: z.number(),
        points: z.number(),
        teams: z
          .array(
            z.object({
              name: z.string(),
              image: z.string().nullable(),
            }),
          )
          .nullable()
          .optional(),
      }),
    )
    .nullable()
    .optional(),
});

export type Tournament = z.infer<typeof TournamentSchema>;
