import { css } from "styled-components";
export const BORDER_RADIUS = "6px";
const BORDER_RADIUS_SMALL = "4px"; // Used mainly for buttons

export const borderRadius = css`
  border-radius: ${BORDER_RADIUS};
`;

export const borderRadiusSmall = css`
  border-radius: ${BORDER_RADIUS_SMALL};
`;

export const borderBottomRadius = css`
  border-bottom-left-radius: ${BORDER_RADIUS};
  border-bottom-right-radius: ${BORDER_RADIUS};
`;

export const borderTopRadius = css`
  border-top-left-radius: ${BORDER_RADIUS};
  border-top-right-radius: ${BORDER_RADIUS};
`;

export const borderLeftRadius = css`
  border-top-left-radius: ${BORDER_RADIUS};
  border-bottom-left-radius: ${BORDER_RADIUS};
`;

export const borderRightRadius = css`
  border-top-right-radius: ${BORDER_RADIUS};
  border-bottom-right-radius: ${BORDER_RADIUS};
`;
