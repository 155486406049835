import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useUsersApi } from "../../../../api/ApiProvider/hooks";
import { queryKeys } from "../../../../api/UsersApiClient/queryKeys";

export function useFetchCurrentUserProfile({ userId, enabled }: { userId?: string; enabled?: boolean }) {
  const userApi = useUsersApi();

  return useQuery({
    queryKey: queryKeys.profile(userId),
    queryFn: () => (userId ? userApi?.getUser(userId) : undefined),
    enabled: enabled && Boolean(userId) && Boolean(userApi),
    retry: false, // Do not retry after failure
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60, // 1 minute cache time
  });
}
