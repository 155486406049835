import { useContext, useMemo } from "react";
import { AuthContext } from "../..";
import { AuthProviderValue } from "../..";

function useAuth(): Omit<AuthProviderValue, "authService"> {
  const authContext = useContext(AuthContext);

  const auth = useMemo(() => {
    if (!authContext) {
      throw new Error("useAuth must be used within an AuthProvider");
    }

    const auth = {
      isAuthenticated: authContext.isAuthenticated,
      hasAccessToken: authContext.hasAccessToken,
      login: authContext.login,
      logout: authContext.logout,
      register: authContext.register,
      resetPasswordRequest: authContext.resetPasswordRequest,
      resendVerificationEmail: authContext.resendVerificationEmail,
      userId: authContext.userId,
      userEmail: authContext.userEmail,
    };

    return auth;
  }, [authContext]);

  return auth;
}

export { useAuth };
