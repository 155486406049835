import { useEffect } from "react";
import { useDdsClient } from "../../../api/ApiProvider/hooks";

import {
  Unsubscribe,
  NewMessagesListener,
  DeletedMessageListener,
  RestoredMessageListener,
  RoomConfigChangeListener,
  SuspensionListener,
  BanListener,
  TriggerPollListener,
  BroadcastsListener,
  PointsReceivedListener,
  EmojiExplosionListener,
} from "../../../api/ApiProvider/DDSClient";

interface DDSEventsOptions {
  roomId?: string;
  newMessagesListener?: NewMessagesListener;
  deletedMessageListener?: DeletedMessageListener;
  restoreMessageListener?: RestoredMessageListener;
  pointsReceivedListener?: PointsReceivedListener;
  roomConfigChangeListener?: RoomConfigChangeListener;
  suspensionListener?: SuspensionListener;
  emojiExplosionListener?: EmojiExplosionListener;
  banListener?: BanListener;
  triggerPollListener?: TriggerPollListener;
  broadcastsListener?: BroadcastsListener;
  disabled?: boolean;
}

const useDDSEvents = ({
  roomId,
  newMessagesListener,
  deletedMessageListener,
  restoreMessageListener,
  roomConfigChangeListener,
  suspensionListener,
  emojiExplosionListener,
  pointsReceivedListener,
  banListener,
  triggerPollListener,
  broadcastsListener,
  disabled,
}: DDSEventsOptions) => {
  const ddsClient = useDdsClient();
  useEffect(() => {
    let sessionStateChangeListener: null | Unsubscribe = null;
    let eventsListener: null | Unsubscribe = null;

    if (disabled) {
      return;
    }

    const subscribeToEvents = () => {
      if (!ddsClient) return;

      eventsListener = ddsClient.subscribe({
        roomId,
        newMessagesListener,
        deletedMessageListener,
        restoreMessageListener,
        pointsReceivedListener,
        roomConfigChangeListener,
        suspensionListener,
        emojiExplosionListener,
        banListener,
        triggerPollListener,
        broadcastsListener,
      });
    };

    if (ddsClient && !ddsClient.sessionOpened) {
      sessionStateChangeListener = ddsClient.onSessionStateChange(() => {
        sessionStateChangeListener?.unsubscribe();
        sessionStateChangeListener = null;
        subscribeToEvents();
      });
    } else {
      subscribeToEvents();
    }

    return () => {
      sessionStateChangeListener?.unsubscribe();
      eventsListener?.unsubscribe();
    };
  }, [
    ddsClient,
    roomId,
    newMessagesListener,
    deletedMessageListener,
    restoreMessageListener,
    roomConfigChangeListener,
    suspensionListener,
    emojiExplosionListener,
    pointsReceivedListener,
    banListener,
    triggerPollListener,
    broadcastsListener,
    disabled,
  ]);
};

export { useDDSEvents };
