import { forwardRef, Ref, useState } from "react";
import { InputWrapper, PasswordShowHideButton, StyledInput } from "./styled";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
  autoCapitalize?: "off" | "none" | "on" | "sentences" | "words" | "characters";
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onEnterKeyPressed?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input = forwardRef(
  ({ type = "text", required = false, onEnterKeyPressed, ...rest }: InputProps, ref: Ref<HTMLInputElement>) => {
    const [isShowingPassword, setIsShowingPassword] = useState(false);

    const input = (
      <StyledInput
        {...rest}
        required={required}
        ref={ref}
        onKeyDown={(event) => {
          if (onEnterKeyPressed && event.key === "Enter") {
            onEnterKeyPressed(event);
          }
        }}
        type={isShowingPassword && type === "password" ? "text" : type}
      />
    );

    if (type !== "password") {
      return input;
    }

    return (
      <InputWrapper>
        {input}
        {type === "password" && (
          <PasswordShowHideButton
            onClick={(event) => {
              event.preventDefault();
              setIsShowingPassword(!isShowingPassword);
            }}
          >
            {isShowingPassword ? "Hide" : "Show"}
          </PasswordShowHideButton>
        )}
      </InputWrapper>
    );
  },
);

Input.displayName = "Input";

export { Input };
