import { forwardRef, ReactNode, Ref } from "react";
import { StyledText, TextTypography } from "./styled";

export type Tag = "p" | "span";
export interface TextProps {
  asTag?: Tag;
  typography?: TextTypography;
  color?: string;
  children?: ReactNode;
  className?: string;
}

const Text = forwardRef(
  (
    { children, typography = "desktopSubHeading", asTag = "span", color, className }: TextProps,
    ref: Ref<HTMLSpanElement>,
  ) => {
    return (
      <StyledText ref={ref} as={asTag} typography={typography} $color={color} className={className}>
        {children}
      </StyledText>
    );
  },
);

Text.displayName = "Text";

export { Text };
