import { z } from "zod";

const baseTournamentSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  format: z.string(),
  startDate: z.string(),
  endDate: z.string().nullable().optional(),
  circuit: z.object({
    uuid: z.string(),
    name: z.string(),
  }),
});

export const TournamentSchema = baseTournamentSchema.extend({
  children: z.array(baseTournamentSchema).nullable().optional(),
  parent: baseTournamentSchema.nullable().optional(),
});

export type Tournament = z.infer<typeof TournamentSchema>;
