import { z } from "zod";
import { blastApi } from "../../../apiClients/blastApi";

export const BroadcastGameIdSchema = z.enum(["cs", "dota"]);

export const BroadcastSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().optional(),
  slug: z.string(),
  live: z.boolean(),
  priority: z.number(),
  chatId: z.string(),
  videoId: z.string(),
  videoSrc: z.string(),
  videoAlternativeSrc: z.string().optional(),
  videoOffset: z.number(),
  videoStartTime: z.string().optional(),
  createdAt: z.string(),
  seriesIds: z.array(z.string()).optional(),
  gameIds: z.array(BroadcastGameIdSchema).default([BroadcastGameIdSchema.Values.cs]),
});

export type Broadcast = z.infer<typeof BroadcastSchema>;

export const getLiveBroadcasts = async () => {
  const { data } = await blastApi.get<unknown>(`/v1/broadcasts/live`);
  return BroadcastSchema.array().parse(data);
};
