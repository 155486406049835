import { z } from "zod";

export const changePasswordRequest = z.object({
  previousPassword: z.string(),
  newPassword: z.string(),
});

export const checkPasswordRequest = z.object({
  password: z.string(),
});

export const changeEmailRequest = z.object({
  newEmail: z.string().email(),
});
