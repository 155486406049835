import camelCase from "lodash/camelCase";

/**
 * Converts all keys in an object to camelCase.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function camelCaseKeys(data: any): object | null | undefined {
  if (data === null || data === undefined) return data;

  if (Array.isArray(data)) {
    return data.map(camelCaseKeys);
  }

  if (typeof data === "object") {
    return Object.entries(data).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [camelCase(key)]: camelCaseKeys(value),
      }),
      {},
    );
  }

  return data;
}

export { camelCaseKeys };
