import { useContext } from "react";
import { TheaterModeContext } from "../../../providers/TheaterModeProvider";

const useTheaterMode = () => {
  const theaterModeContext = useContext(TheaterModeContext);
  if (!theaterModeContext) {
    throw new Error("useTheaterModeContext must be used within a TheaterModeContext");
  }
  return {
    ...theaterModeContext,
  };
};

export { useTheaterMode };
