import { client } from "../../../apiClients/sanity";
import { MetaTagsInfo } from "../types/MetaTagsInfo";

interface SanityContentPage {
  metaTagsInfo: MetaTagsInfo;
  route: string;
  title: string;
}

const GhostQuery = (route: string) => {
  // https://www.sanity.io/docs/query-cheat-sheet
  const query = `*[_type == "ghostPage" && lower(route) == lower("${route}")] | order(_updatedAt desc) {
  ...,
}[0]`;
  return query;
};

async function getGhostPage(route: string): Promise<SanityContentPage> {
  const query = GhostQuery(route);

  const data = await client.fetch(query).catch((error) => {
    throw new Error("failed to fetch CMS data", { cause: error });
  });

  return data;
}

export { getGhostPage };
