import classNames from "classnames";
import { MobileNavParent } from "./components/MobileNavParent";
import { MobileNavLink } from "./components/MobileNavItem";
import { Transition } from "@headlessui/react";
import { GameNav } from "../GameNav";
import { NavbarTopLevelItem } from "@data/sanity/getSettings/schemas/NavbarSchema";
import { useAuth } from "../../../../../auth/AuthProvider/hooks/useAuth";
import { useGameId } from "@providers/GameIdProvider/useGameId";
import { HomeLink } from "../HomeLink";
import { Logo } from "../Logo";
import { DotaSearchButton } from "../DotaSearchButton";
import { useNavBarContext } from "../../useNavBarContext";
import { useLiveNavItem } from "../../hooks/useLiveNavItem";
import { TournamentTimelineNav } from "../TournamentTimelineNav";
import { useLocation } from "react-router-dom";

export const MobileBurgerMenu = ({
  className,
  navbarItems,
  isOpen,
  onClose,
}: {
  className?: string;
  navbarItems: NavbarTopLevelItem[];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { showDotaSearchButton } = useNavBarContext();
  const { logout, isAuthenticated } = useAuth();
  const { gameId } = useGameId();
  const { navbarItem: liveNavbarItem } = useLiveNavItem();
  const { pathname } = useLocation();

  return (
    <Transition
      as="div"
      show={isOpen}
      enter="transition-opacity duration-200 ease-in-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity delay-100 duration-200 ease-in-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={classNames("h-[calc(100%-2.75rem)] w-full backdrop-blur-sm", className)}
      onClick={() => onClose()}
    >
      <Transition
        as="div"
        show={isOpen}
        enter="transition-transform duration-200 ease-in-out"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition-transform duration-200 ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className={classNames(
          "flex h-full w-80 flex-col items-start gap-9 overflow-hidden overflow-y-auto bg-canvas-100 px-7 pb-24 pt-7 transition-all duration-300 ease-in-out scrollbar-none",
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {!gameId && (
          <HomeLink>
            <Logo className="!w-8" />
          </HomeLink>
        )}
        <GameNav />
        {gameId && (
          <HomeLink
            onClick={onClose}
            className={classNames("border-y-2 border-transparent pb-2 transition-all font-style-navigation-mobile", {
              "border-b-yellow": pathname === `/${gameId}`,
              "text-neutral-0": pathname !== `/${gameId}`,
            })}
          >
            Home
          </HomeLink>
        )}
        {liveNavbarItem && <MobileNavParent parent={liveNavbarItem} isLiveOption />}
        {navbarItems.map((item) =>
          item._type === "navbarTournamentsItem" ? (
            <TournamentTimelineNav gameId={gameId} key={item._key} topLevelNavItem={item} onNavLinkClick={onClose} />
          ) : item.children ? (
            <MobileNavParent key={item._key} parent={item} onNavLinkClick={onClose} />
          ) : (
            <MobileNavLink key={item._key} navbarItem={item} topLevel onClick={onClose} />
          ),
        )}
        {showDotaSearchButton && <DotaSearchButton onClick={onClose} />}
        {isAuthenticated && (
          <button className="mt-auto pt-4 text-neutral-50 font-style-navigation-mobile" onClick={() => void logout()}>
            Sign out
          </button>
        )}
      </Transition>
    </Transition>
  );
};
