import { AxiosInstance } from "axios";
export class IngestionApiClient {
  public constructor(private readonly client: AxiosInstance) {}

  public postLivedrop = async ({ stream }: { stream: string }): Promise<number> => {
    const { data } = await this.client.post(`v1/points/processor/ingestion/livedrop/${stream}`);
    return data;
  };
  public postPage = async (): Promise<number> => {
    const { data } = await this.client.post(`v1/points/processor/ingestion/client/page`);
    return data;
  };
  public postEvent = async ({ event }: { event: string }): Promise<number> => {
    const { data } = await this.client.post(`v1/points/processor/ingestion/client/event`, {
      event,
    });
    return data;
  };
}
