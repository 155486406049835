export const PauseIcon = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.6" cx="25" cy="25" r="25" transform="matrix(-1 0 0 1 50 0)" fill="#1F0C19" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6875 16C20.6195 16 21.375 16.6362 21.375 17.4211L21.375 32.5789C21.375 33.3638 20.6195 34 19.6875 34C18.7555 34 18 33.3638 18 32.5789L18 17.4211C18 16.6362 18.7555 16 19.6875 16Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.8125 16C30.7445 16 31.5 16.6362 31.5 17.4211L31.5 32.5789C31.5 33.3638 30.7445 34 29.8125 34C28.8805 34 28.125 33.3638 28.125 32.5789L28.125 17.4211C28.125 16.6362 28.8805 16 29.8125 16Z"
      fill="white"
    />
  </svg>
);
