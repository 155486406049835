import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import { Image } from "../../data/sanity/types/Image";
import { environment, sanityDataset } from "../../../config";
import { clientWithBudget } from "./clientWithBudget";
import { Audio } from "@data/sanity/types/Audio";

const sanityClient = createClient({
  // Find your project ID and dataset in `sanity.json` in your studio project
  projectId: "6znhzi10",
  dataset: sanityDataset ?? "production",
  apiVersion: "2023-05-31", // use the current UTC date - see "specifying API version"!
  useCdn: environment === "production", // Gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application requires the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
});

const builder = imageUrlBuilder(sanityClient);

export const urlFor = (source: Image) => {
  return builder.image(source);
};

export const urlForAudio = (source: Audio) => {
  const splitRef = source.asset._ref.split("-");
  const id = splitRef[1];
  const extension = splitRef[2];
  return `https://cdn.sanity.io/files/${sanityClient.config().projectId}/${sanityClient.config().dataset}/${id}.${extension}`;
};

export const client = clientWithBudget(sanityClient);
