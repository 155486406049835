import { AxiosInstance } from "axios";
import { Message } from "../../types/Message";
import { Room } from "../../types/Room";
import { TaggingUser } from "../../types/TaggingUser";
import { GetChatMessagesOptions, SearchTaggingUsersOptions, SendChatMessageOptions, Suspension } from "./types";

export class ChatApiClient {
  constructor(
    private readonly client: AxiosInstance,
    private readonly dataBucketClient: AxiosInstance,
  ) {}

  public getChatMessages = async (
    options: GetChatMessagesOptions,
  ): Promise<{ nextPageToken?: string; messages: Array<Message> }> => {
    options.limit ??= 30;

    const { data } = await this.client.get("/v1/chat/messages", {
      params: options,
    });

    return data;
  };

  public getChatRoomConfig = async (uuid: string): Promise<Room> => {
    const { data } = await this.client.get(`/v1/chat/rooms/${uuid}`);

    return data;
  };

  public sendChatMessage = async (body: SendChatMessageOptions): Promise<Message> => {
    const { data } = await this.client.post("/v1/chat/messages", body);

    return data;
  };

  public searchTaggingUsers = async (params: SearchTaggingUsersOptions) => {
    let { data: users } = await this.dataBucketClient.get<Array<TaggingUser>>(
      `/chat/online-users-data/${params.roomUuid}.json`,
    );

    if (typeof params.query === "string" && !!params.query.trim()) {
      const query = params.query.trim().toLowerCase();
      users = users
        .filter((user) => user.username.toLowerCase().includes(query))
        .sort(({ username: firstUsername }, { username: secondUsername }) => {
          firstUsername = firstUsername.toLowerCase();
          secondUsername = secondUsername.toLowerCase();
          if (
            (firstUsername.startsWith(query) && secondUsername.startsWith(query)) ||
            (!firstUsername.startsWith(query) && !secondUsername.startsWith(query))
          ) {
            return firstUsername < secondUsername ? -1 : Number(firstUsername !== secondUsername);
          }

          return firstUsername.startsWith(query) ? -1 : 1;
        });
    }

    return users.slice(0, 20);
  };

  public getActiveSuspension = async (): Promise<Suspension[]> => {
    const { data } = await this.client.get("/v1/chat/suspensions", {
      params: {
        active: true,
      },
    });

    return data.items;
  };
}
