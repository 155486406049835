import { useQuery } from "@tanstack/react-query";
import { getLiveBroadcasts } from ".";

const useGetLiveBroadcasts = ({ enabled }: { enabled?: boolean } = {}) => {
  return useQuery({
    queryKey: ["liveBroadcasts"],
    queryFn: getLiveBroadcasts,
    enabled: enabled === undefined ? true : enabled,
  });
};

export { useGetLiveBroadcasts };
