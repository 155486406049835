export const ReturnIcon = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.6" cx="25" cy="25" r="25" transform="matrix(-1 0 0 1 50 0)" fill="#1F0C19" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 18.3333C13 16.4924 14.4924 15 16.3333 15H33.5556C35.3965 15 36.8889 16.4924 36.8889 18.3333V31.6667C36.8889 33.5076 35.3965 35 33.5556 35H16.3333C14.4924 35 13 33.5076 13 31.6667V18.3333ZM16.3333 17.2222C15.7197 17.2222 15.2222 17.7197 15.2222 18.3333V31.6667C15.2222 32.2803 15.7197 32.7778 16.3333 32.7778H33.5556C34.1692 32.7778 34.6667 32.2803 34.6667 31.6667V18.3333C34.6667 17.7197 34.1692 17.2222 33.5556 17.2222H16.3333Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 28.3333C13 27.106 13.9949 26.1111 15.2222 26.1111H23C24.2273 26.1111 25.2222 27.106 25.2222 28.3333V32.7778C25.2222 34.0051 24.2273 35 23 35H15.2222C13.9949 35 13 34.0051 13 32.7778V28.3333ZM23 29.4444C23 28.8308 22.5025 28.3333 21.8889 28.3333H16.3333C15.7197 28.3333 15.2222 28.8308 15.2222 29.4444V32.7778H21.8889C22.5025 32.7778 23 32.2803 23 31.6666V29.4444Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.3872 19.8889L31.3872 19.8889C31.7554 19.8889 32.0539 20.1874 32.0539 20.5556L32.0539 26.5556C32.0539 26.9238 31.7554 27.2222 31.3872 27.2222C31.019 27.2222 30.7206 26.9238 30.7206 26.5556L30.7206 22.1651L26.8889 25.9967C26.6286 26.2571 26.2065 26.2571 25.9461 25.9967C25.6858 25.7364 25.6858 25.3143 25.9461 25.0539L29.7778 21.2222L25.3872 21.2222C25.019 21.2222 24.7206 20.9238 24.7206 20.5556C24.7206 20.1874 25.019 19.8889 25.3872 19.8889Z"
      fill="white"
    />
  </svg>
);
