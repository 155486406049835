import { AxiosError, AxiosInstance } from "axios";
import { PlayerPicks, PlayerPicksSchema } from "../shared/types/playerPicks";
import { FantasyPlayerPickRatesSchema } from "./schemas";
import { formatCircuit, formatTournament } from "./helpers/formatStrings";

export class FantasyDataBucketApiClient {
  public constructor(private readonly dataBucketClient: AxiosInstance) {}

  queryKeys = {
    all: ["fantasyDataBucket"] as const,
    playerPicks: ({ circuitName, tournamentName }: { circuitName?: string; tournamentName?: string }) =>
      [...this.queryKeys.all, "playerPicks", circuitName, tournamentName] as const,
  };

  public getPlayerPicksForTournament = async ({
    circuitName,
    tournamentName,
  }: {
    circuitName: string;
    tournamentName: string;
  }): Promise<PlayerPicks | null> => {
    const formattedCircuit = formatCircuit(circuitName);
    const formattedTournamentName = formatTournament(tournamentName);

    const { data, status } = await this.dataBucketClient.get(
      `fantasy/statistics/${formattedCircuit || circuitName}/${
        formattedTournamentName || tournamentName
      }/pickRates.json`,
      { validateStatus: (status) => status === 200 || status === 404 },
    );

    if (status === 404) return null;

    try {
      return PlayerPicksSchema.parse(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error); // helpful for debugging zod errors
      throw error;
    }
  };

  public getPlayerPickRates = async ({ seasonId, eventId }: { seasonId: string; eventId: string }) => {
    try {
      const { data } = await this.dataBucketClient.get(`fantasy/statistics/${seasonId}/${eventId}/pickRates.json`);
      return FantasyPlayerPickRatesSchema.parse(data);
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 404) {
        return null;
      }

      // eslint-disable-next-line no-console
      console.error(error); // helpful for debugging zod errors
      throw error;
    }
  };
}
