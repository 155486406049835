import { captureMessage } from "@sentry/browser";
import { ComponentType, lazy } from "react";

const cacheKey = "blasttv:force-refreshed";

const getHasRefreshed = () => JSON.parse(window.sessionStorage.getItem(cacheKey) || "false") === true;

const setHasRefreshed = (value: boolean) => window.sessionStorage.setItem(cacheKey, value.toString());

/*
 * This is a wrapper around React.lazy that will force a refresh of the page
 * if the chunk fails to load.
 * This is useful when the chunk is missing due to a deployment.
 * Described here: https://rollbar.com/blog/javascript-chunk-load-error/
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function lazyWithRefresh<T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) {
  return lazy(() => {
    return new Promise<{ default: T }>((resolve, reject) => {
      factory()
        .then((module) => {
          // reset refresh flag on successful load of missing chunk
          setHasRefreshed(false);
          resolve(module);
        })
        .catch((error) => {
          if (getHasRefreshed()) {
            // already attempted refresh to load missing chunk - don't enter a refresh loop
            // let the error bubble up
            reject(error);
            // throw error;
          } else {
            // force refresh to load missing chunk
            setHasRefreshed(true);
            captureMessage("Forced refresh to load missing chunk");
            window.location.reload();
          }
        });
    });
  });
}

export { lazyWithRefresh };
