import classNames from "classnames";

export const ExternalArrow = ({ className }: { className?: string }) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames("inline", className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.79566 8.45333L9.32772 8.45332L9.32775 0.256961L0.814337 0.256952L0.814284 1.78893L6.78417 1.78893L1.75091 6.8222L2.80524 7.87653L7.79566 2.88611L7.79566 8.45333Z"
      fill="currentColor"
    />
  </svg>
);
