import { AxiosInstance } from "axios";
import { PickemsUserResult, PostUserPicksPayload, PostUserPicksResponse } from "./types";
import { PostPlayerPicksPayload, UserPlayerPicks, UserPlayerPicksSchema } from "./schemas";

export class PickemsApiClient {
  public constructor(private readonly client: AxiosInstance) {}

  public postUserPicksInBulk = async ({ pickemsId, picks }: PostUserPicksPayload): Promise<PostUserPicksResponse> => {
    const { data } = await this.client.post(`/v1/pickems/${pickemsId}/my-picks`, {
      picks,
    });

    return data;
  };

  public getUserPicks = async ({ pickemsId }: { pickemsId: string }): Promise<PickemsUserResult[]> => {
    const { data } = await this.client.get<PickemsUserResult[]>(`/v1/pickems/${pickemsId}/my-picks`);

    return data?.map((pick) => {
      return {
        ...pick,
        choiceId: decodeURIComponent(pick.choiceId),
      };
    });
  };

  postPlayerPicks = async (pickemsId: string, payload: PostPlayerPicksPayload): Promise<void> => {
    await this.client.post(`/v1/player-pickems/${pickemsId}/my-picks`, payload);
  };

  public getUserPlayerPicks = async ({ pickemsId }: { pickemsId: string }): Promise<UserPlayerPicks[]> => {
    const { data } = await this.client.get(`/v1/player-pickems/${pickemsId}/my-picks`);

    return UserPlayerPicksSchema.array().parse(data);
  };
}
