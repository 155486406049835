export const formatCircuit = (circuitName: string) => {
  const splittedString = circuitName.split(" ");
  splittedString.shift();

  return splittedString.join("-").toLowerCase().replaceAll(" ", "-");
};

export const formatTournament = (tournamentName: string) => {
  const splittedString = tournamentName.split(" ");
  splittedString.pop();

  return splittedString.join("-").toLowerCase().replaceAll(" ", "-");
};
