import { ReactNode, createContext } from "react";
import { useGameIdFromLocation } from "./useGameIdFromLocation";
import { z } from "zod";

export const GameIdSchema = z.enum(["cs", "dota", "gaming", "supercell"]);
export type GameId = z.infer<typeof GameIdSchema>;
const allGameIds: GameId[] = ["cs", "dota", "gaming", "supercell"];

type GameIdContextValue = {
  gameId: GameId | null; // current game id, null means global context
  enabledGames: GameId[]; // game ids that are enabled via feature flags
  allGameIds: GameId[]; // all supported game ids
};

export const GameIdContext = createContext<GameIdContextValue>({
  gameId: null,
  allGameIds,
  enabledGames: ["cs", "dota", "supercell", "gaming"],
});

export const GameIdProvider = ({ children }: { children: ReactNode }) => {
  const enabledGames: GameId[] = ["cs", "dota", "supercell", "gaming"];

  const gameIdFromLocation = useGameIdFromLocation({ enabledGames });
  const gameId = enabledGames.length === 1 ? enabledGames[0] : gameIdFromLocation;

  return <GameIdContext.Provider value={{ allGameIds, gameId, enabledGames }}>{children}</GameIdContext.Provider>;
};
