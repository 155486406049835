import { toRem } from "@shared/helpers/toRem";
import { colors } from "@blastorg/portal-pattern-library";
import styled, { keyframes } from "styled-components";

const loaderLineAnim = keyframes`
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 80%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
`;

const LoaderLine = styled.div`
  width: ${toRem(200)};
  height: ${toRem(3)};
  position: relative;
  overflow: hidden;
  background-color: ${colors.neutral20}90;
  margin: ${toRem(100)} auto;

  &::before {
    content: "";
    position: absolute;
    left: -50%;
    height: ${toRem(3)};
    width: 40%;
    background-color: ${colors.canvas100}50;
    animation: ${loaderLineAnim} 1s linear infinite;
    border-radius: ${toRem(20)};
  }
`;

interface SpinnerProps {
  className?: string;
}

export function Loader({ className }: SpinnerProps) {
  return <LoaderLine className={className}></LoaderLine>;
}
