import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUsersApi } from "../../../api/ApiProvider/hooks";
import { queryKeys } from "../../../api/UsersApiClient/queryKeys";
import { PatchUserProfileRequest } from "../../../api/UsersApiClient/types";

export function useUpdateProfile({ userId }: { userId?: string }) {
  const queryClient = useQueryClient();
  const usersApi = useUsersApi();

  if (!usersApi) {
    throw new Error("Users API is not available");
  }

  const mutation = useMutation({
    mutationFn: (patchUser: PatchUserProfileRequest) => {
      if (!userId) throw new Error("userId is not defined");
      return usersApi.patchUser(userId, patchUser);
    },
    onSuccess: () => {
      // Invalidate the profile query to refetch the user's profile
      queryClient.invalidateQueries({ queryKey: queryKeys.profile(userId) });
    },
  });

  return mutation;
}
