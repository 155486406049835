import { StorageService } from "./StorageService";

interface VideoSettings {
  muted: boolean;
}

class VideoStorageService {
  private storageService: StorageService<{ video: VideoSettings }>;
  constructor() {
    this.storageService = new StorageService<{ video: VideoSettings }>(sessionStorage);
  }
  set(value: VideoSettings): void {
    this.storageService.set("video", value);
  }
  get(): VideoSettings {
    const value = this.storageService.get("video");
    return { muted: value?.muted ?? true };
  }
}

export const videoStorageService = new VideoStorageService();
