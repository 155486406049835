import { createContext, useEffect, useMemo } from "react";
import { captureException } from "@sentry/browser";
import { ApiService } from "../ApiService";
import { DDSClient } from "./DDSClient";

export interface ApiProviderValue {
  apiService: ApiService;
  ddsClient?: DDSClient;
}

export const ApiContext = createContext<ApiProviderValue | undefined>(undefined);

function createApiService() {
  return new ApiService();
}

// When the DDS client is instantiated, re-use the instance and update the connection options
let ddsClient: DDSClient;
function getDdsClient() {
  if (ddsClient === undefined) {
    ddsClient = new DDSClient();
  }

  return ddsClient;
}

/**
 * ApiProvider provides the API service and DDS client to the application
 * @param children The children to render
 * @param ddsDisabled Flag to disable the DDSClient connection for testing purposes
 */
function ApiProvider({ children, ddsDisabled = false }: { children?: React.ReactNode; ddsDisabled?: boolean }) {
  const ddsClient = ddsDisabled ? undefined : getDdsClient();

  const providerValue: ApiProviderValue = useMemo(() => {
    return {
      apiService: createApiService(),
      ddsClient,
    };
  }, [ddsClient]);

  // Connect DDS client always
  // To track logged-in user BAN event and more if needed
  useEffect(() => {
    if (!ddsClient) return;

    let connectionOpened = false;
    const timer = window.setTimeout(() => {
      ddsClient.open().catch((error) => {
        captureException(`Error while opening DDS connection: ${error}`);
      });
      connectionOpened = true;
    }, 200);

    return () => {
      window.clearTimeout(timer);
      if (connectionOpened) {
        ddsClient.close();
      }
    };
  }, [ddsClient]);

  return <ApiContext.Provider value={providerValue}>{children}</ApiContext.Provider>;
}

export { ApiProvider };
