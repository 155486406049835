import {
  Listener,
  SubscribeArgs,
  Unsubscribe,
  DdsEdgeMessage,
  Ping,
  ChatRoom,
  UserAction,
  ConnectionMetadata,
  ReconnectListener,
  SessionStateListener,
  BroadcastMessage,
} from "./types";
import { TriggerPoll } from "../../../types/Triggers";
import axios, { AxiosInstance } from "axios";
import { authInterceptor } from "../../../auth/authInterceptor";
import { baseAssetsURL } from "../../../config";

export * from "./types";

export class DDSClient {
  private _sessionOpened = false;
  private _client!: AxiosInstance;
  private readonly ddsBaseURL: string;

  public get sessionOpened() {
    return this._sessionOpened;
  }

  private set sessionOpened(value: boolean) {
    this._sessionOpened = value;
    this.triggerSessionStateChange();
  }

  private sseInstance: null | EventSource = null;
  private connectionId: null | string = null;
  private listeners: Array<Listener> = [];
  private reconnectListeners: Array<ReconnectListener> = [];
  private sessionStateListeners: Array<SessionStateListener> = [];
  private closingTimer: null | number = null;
  private connectionTimeout: null | number = null;

  constructor() {
    const url = new URL(baseAssetsURL);
    // cut off the subdomain
    url.hostname = `ddsedge.${url.hostname.split(".").slice(-2).join(".")}`;
    // generating URL https://ddsedge.blast.tv
    this.ddsBaseURL = url.origin;

    this._client = axios.create({
      baseURL: this.ddsBaseURL,
      withCredentials: true,
    });
    this._client.interceptors.request.use(authInterceptor);

    this.onMessage = this.onMessage.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  public open(): Promise<void> {
    return this.openConnection();
  }

  public close(): void {
    this.closeConnection();
  }

  public subscribe({
    roomId,
    newMessagesListener,
    deletedMessageListener,
    restoreMessageListener,
    suspensionListener,
    emojiExplosionListener,
    banListener,
    roomConfigChangeListener,
    triggerPollListener,
    broadcastsListener,
    pointsReceivedListener,
  }: SubscribeArgs): Unsubscribe {
    const subscriptionId: string = Math.random().toString(32).split(".")[1];

    if (
      (newMessagesListener ||
        deletedMessageListener ||
        restoreMessageListener ||
        roomConfigChangeListener ||
        emojiExplosionListener) &&
      roomId &&
      !this.roomExists(roomId)
    ) {
      this.subscribeToRoom(roomId);
    }
    if ((suspensionListener || banListener || pointsReceivedListener) && !this.userActionSubscriptionExists()) {
      this.subscribeToUserActions();
    }

    if (triggerPollListener && !this.triggerSubscriptionExists()) {
      this.subscribeToTriggers();
    }

    if (broadcastsListener && !this.broadcastsSubscriptionExists()) {
      this.subscribeToBroadcasts();
    }

    this.listeners.push({
      id: subscriptionId,
      roomId,
      newMessagesListener,
      deletedMessageListener,
      pointsReceivedListener,
      restoreMessageListener,
      suspensionListener,
      emojiExplosionListener,
      banListener,
      roomConfigChangeListener,
      triggerPollListener,
      broadcastsListener,
    });

    return {
      unsubscribe: (): boolean => {
        try {
          const messageListenerIndex: number = this.listeners.findIndex(
            (messageListener) => messageListener.id === subscriptionId,
          );

          if (messageListenerIndex > -1) {
            const removedListener = this.listeners.splice(messageListenerIndex, 1)[0];

            if (
              (removedListener.newMessagesListener ||
                removedListener.emojiExplosionListener ||
                removedListener.deletedMessageListener ||
                removedListener.restoreMessageListener ||
                removedListener.roomConfigChangeListener) &&
              roomId &&
              !this.roomExists(roomId)
            ) {
              this.unsubscribeFromRoom(roomId);
            }
            if (
              (removedListener.suspensionListener ||
                removedListener.pointsReceivedListener ||
                removedListener.banListener) &&
              !this.userActionSubscriptionExists()
            ) {
              this.unsubscribeUserActions();
            }
            if (removedListener.triggerPollListener && !this.triggerSubscriptionExists()) {
              this.unsubscribeFromTriggers();
            }
            if (removedListener.broadcastsListener && !this.broadcastsSubscriptionExists()) {
              this.unsubscribeFromBroadcasts();
            }
          }

          return true;
        } catch {
          return false;
        }
      },
    };
  }

  public reconnect(): void {
    this.closeConnection();
    this.openConnection().then(() => {
      this.triggerReconnect();
      const sessionStateChangeSub = this.onSessionStateChange((sessionOpened) => {
        if (!sessionOpened) {
          return;
        }
        sessionStateChangeSub.unsubscribe();

        const rooms: Set<string> = new Set(
          this.listeners.map((messageListener) => messageListener.roomId).filter(Boolean) as Array<string>,
        );

        rooms.forEach((roomId: string) => {
          this.subscribeToRoom(roomId);
        });

        if (this.userActionSubscriptionExists()) {
          this.subscribeToUserActions();
        }
        if (this.triggerSubscriptionExists()) {
          this.subscribeToTriggers();
        }
        if (this.broadcastsSubscriptionExists()) {
          this.subscribeToBroadcasts();
        }
      });
    });
  }

  public onReconnect(callback: () => void): Unsubscribe {
    const reconnectId: string = Math.random().toString(32).split(".")[1];

    this.reconnectListeners.push({
      id: reconnectId,
      callback,
    });

    return {
      unsubscribe: (): boolean => {
        try {
          const listenerIndex: number = this.reconnectListeners.findIndex((listener) => listener.id === reconnectId);

          if (listenerIndex > -1) {
            this.reconnectListeners.splice(listenerIndex, 1);
          }

          return true;
        } catch {
          return false;
        }
      },
    };
  }

  public onSessionStateChange(callback: (value: boolean) => void): Unsubscribe {
    const sessionStateId: string = Math.random().toString(32).split(".")[1];

    this.sessionStateListeners.push({
      id: sessionStateId,
      callback,
    });

    return {
      unsubscribe: (): boolean => {
        try {
          const listenerIndex: number = this.sessionStateListeners.findIndex(
            (listener) => listener.id === sessionStateId,
          );

          if (listenerIndex > -1) {
            this.sessionStateListeners.splice(listenerIndex, 1);
          }

          return true;
        } catch {
          return false;
        }
      },
    };
  }

  private closeConnection(): void {
    this.stopStateWatcher();

    if (this.sseInstance === null) {
      return;
    }

    this.sseInstance.removeEventListener("message", this.onMessage);
    this.sseInstance.removeEventListener("error", this.onClose);
    this.sseInstance.close();
    this.sseInstance = null;

    if (this.closingTimer !== null) {
      window.clearTimeout(this.closingTimer);
    }
    this.connectionId = null;
    this.sessionOpened = false;
  }

  private openConnection(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.sseInstance = new EventSource(`${this.ddsBaseURL}/v1/c/connect`, {
        withCredentials: true,
      });
      this.sseInstance.addEventListener("message", this.onMessage);
      this.sseInstance.addEventListener("error", this.onClose);
      this.sseInstance.addEventListener("open", () => {
        resolve();
      });
    });
  }

  private onClose(): void {
    this.closingTimer = window.setTimeout(() => {
      this.closingTimer = null;
      this.reconnect();
    }, 1000);
  }

  private stopStateWatcher(): void {
    if (this.connectionTimeout !== null) {
      window.clearTimeout(this.connectionTimeout);
      this.connectionTimeout = null;
    }
  }

  private watchConnectionState(): void {
    this.stopStateWatcher();
    this.connectionTimeout = window.setTimeout(() => {
      this.reconnect();
    }, 20000);
  }

  private triggerReconnect() {
    this.reconnectListeners.forEach((listener) => {
      setTimeout(() => {
        listener.callback();
      }, 1);
    });
  }

  private triggerSessionStateChange() {
    this.sessionStateListeners.forEach((listener) => {
      setTimeout(() => {
        listener.callback(this.sessionOpened);
      }, 1);
    });
  }

  private onMessage(event: MessageEvent): void {
    try {
      const data: DdsEdgeMessage = JSON.parse(event.data);

      switch (data.type) {
        case "connection_metadata": {
          this.onConnectionMetaData(data.connection_metadata);
          break;
        }
        case "ping": {
          this.onPing(data.ping);
          break;
        }
        case "chat_room": {
          this.onChatRoom(data.chat_room);
          break;
        }
        case "user": {
          this.onUserAction(data.user);
          break;
        }
        case "trigger": {
          if (data.trigger?.type === "poll") {
            this.onTriggerPoll(data.trigger.data as TriggerPoll);
          }
          break;
        }
        case "broadcast": {
          this.onBroadcast(data.broadcast);
          break;
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }

  private onConnectionMetaData(connectionMetadata: ConnectionMetadata): void {
    this.connectionId = connectionMetadata.connection_id;
    this.sessionOpened = true;
  }

  private onPing(ping: Ping): void {
    this._client
      .post(`/v1/c/pong`, {
        token: ping.token,
      })
      .catch(() => null);
    this.watchConnectionState();
  }

  private onChatRoom(chatRoom: ChatRoom): void {
    switch (chatRoom.type) {
      case "emoji_explosion": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.emojiExplosionListener === "function"
          ) {
            setTimeout(() => {
              messageListener.emojiExplosionListener?.(chatRoom.emoji_explosion);
            }, 1);
          }
        });
        break;
      }
      case "message": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.newMessagesListener === "function"
          ) {
            setTimeout(() => {
              messageListener.newMessagesListener?.(chatRoom.message);
            }, 1);
          }
        });
        break;
      }
      case "message_delete": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.deletedMessageListener === "function"
          ) {
            setTimeout(() => {
              messageListener.deletedMessageListener?.(chatRoom.message_delete);
            }, 1);
          }
        });
        break;
      }
      case "message_restore": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.restoreMessageListener === "function"
          ) {
            setTimeout(() => {
              messageListener.restoreMessageListener?.(chatRoom.message_restore);
            }, 1);
          }
        });
        break;
      }
      case "room_config": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.roomConfigChangeListener === "function"
          ) {
            setTimeout(() => {
              messageListener.roomConfigChangeListener?.(chatRoom.room_config);
            }, 1);
          }
        });
        break;
      }
    }
  }

  private onUserAction(userAction: UserAction): void {
    switch (userAction.type) {
      case "user_suspension": {
        this.listeners.forEach((messageListener) => {
          if (typeof messageListener.suspensionListener === "function") {
            setTimeout(() => {
              messageListener.suspensionListener?.(userAction.user_suspension);
            }, 1);
          }
        });
        break;
      }

      case "points_received": {
        this.listeners.forEach((messageListener) => {
          if (typeof messageListener.pointsReceivedListener === "function") {
            setTimeout(() => {
              messageListener.pointsReceivedListener?.(userAction.points_received);
            }, 1);
          }
        });
        break;
      }

      case "ban": {
        this.listeners.forEach((messageListener) => {
          if (typeof messageListener.banListener === "function") {
            setTimeout(() => {
              messageListener.banListener?.(userAction.ban);
            }, 1);
          }
        });
        break;
      }
    }
  }

  private onTriggerPoll(trigger: TriggerPoll): void {
    this.listeners.forEach((messageListener) => {
      if (typeof messageListener.triggerPollListener === "function") {
        setTimeout(() => {
          messageListener.triggerPollListener?.(trigger);
        }, 1);
      }
    });
  }

  private onBroadcast(broadcastMessage: BroadcastMessage): void {
    this.listeners.forEach((messageListener) => {
      if (typeof messageListener.broadcastsListener === "function") {
        setTimeout(() => {
          messageListener.broadcastsListener?.(broadcastMessage);
        }, 1);
      }
    });
  }

  private subscribeToRoom(roomId: string): void {
    this.subscribeTo("chat_room", {
      room_uuid: roomId,
    });
  }

  private unsubscribeFromRoom(roomId: string): void {
    this.unsubscribeFrom("chat_room", {
      room_uuid: roomId,
    });
  }

  private subscribeToUserActions(): void {
    this.subscribeTo("user", {});
  }

  private unsubscribeUserActions(): void {
    this.unsubscribeFrom("user", {});
  }

  private subscribeToTriggers(): void {
    this.subscribeTo("trigger", {
      trigger_id: "global",
    });
  }

  private unsubscribeFromTriggers(): void {
    this.unsubscribeFrom("trigger", {
      trigger_id: "global",
    });
  }

  private subscribeToBroadcasts(): void {
    this.subscribeTo("broadcast", {
      broadcast_id: "global",
    });
  }

  private unsubscribeFromBroadcasts(): void {
    this.unsubscribeFrom("broadcast", {
      broadcast_id: "global",
    });
  }

  private subscribeTo(type: string, body: object): void {
    if (!this.sessionOpened) {
      return;
    }

    const requestBody: object = {
      type,
      connection_id: this.connectionId,
      [type]: body,
    };

    this._client
      .post(`/v1/c/subscription`, requestBody, {
        withCredentials: true,
      })
      .catch(() => null);
  }

  private unsubscribeFrom(type: string, body: object): void {
    if (!this.sessionOpened) {
      return;
    }

    const requestBody: object = {
      type,
      connection_id: this.connectionId,
      [type]: body,
    };

    this._client
      .post(`/v1/c/subscription/unsubscribe`, requestBody, {
        withCredentials: true,
      })
      .catch(() => null);
  }

  private roomExists(roomId: string): boolean {
    return this.listeners.some((listener) => listener.roomId === roomId);
  }

  private userActionSubscriptionExists(): boolean {
    return this.listeners.some(
      (listener) =>
        typeof listener.suspensionListener === "function" ||
        typeof listener.banListener === "function" ||
        typeof listener.pointsReceivedListener === "function",
    );
  }

  private triggerSubscriptionExists(): boolean {
    return this.listeners.some((listener) => typeof listener.triggerPollListener === "function");
  }

  private broadcastsSubscriptionExists(): boolean {
    return this.listeners.some((listener) => typeof listener.broadcastsListener === "function");
  }
}
