import { z } from "zod";

const KeyMatchSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.string(),
  scheduledAt: z.string(),
  teamA: z
    .object({
      id: z.string(),
      name: z.string(),
      shortName: z.string(),
      nationality: z.string().nullish(),
    })
    .nullish(),
  teamB: z
    .object({
      id: z.string(),
      name: z.string(),
      shortName: z.string(),
      nationality: z.string().nullish(),
    })
    .nullish(),
  teamAScore: z.number().nullish(),
  teamBScore: z.number().nullish(),
});

export type KeyMatches = z.infer<typeof KeyMatchSchema>;
const TournamentSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  location: z.string().nullish(),
  prizePool: z.string().nullish(),
  numberOfTeams: z.number().nullish(),
  keyMatches: KeyMatchSchema.array().optional(),
});

export const TournamentTimelineSchema = z.object({
  upcoming: z.array(TournamentSchema),
  past: z.array(TournamentSchema),
});

export type Tournament = z.infer<typeof TournamentSchema>;
