import { useContext } from "react";
import { NavBarContext } from ".";

export const useNavBarContext = () => {
  const contextValue = useContext(NavBarContext);
  if (!contextValue) {
    throw new Error("useNavBarContext must be used within NavBarProvider");
  }
  return contextValue;
};
