import { useEffect, useState } from "react";
import { useGameId } from "@providers/GameIdProvider/useGameId";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { GameIcon } from "@shared/components/GameIcon";
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/react-v2";
import { Icon } from "@shared/components/Icon";
import { isSsrPage } from "../../../../../ssrPagesRoutes/isSsrPage";
import { environment, ssrSiteUrl } from "../../../../config";
import { gameTitles } from "@providers/GameIdProvider/constants";

export const GameNav = ({ className }: { className?: string }) => {
  const { gameId, enabledGames: enabledGameIds } = useGameId();
  const [selectedGameId, setSelectedGameId] = useState<typeof gameId>(gameId);
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedGameId(gameId);
  }, [gameId]);

  if (enabledGameIds.length <= 1) {
    return null;
  }

  if (!selectedGameId) {
    return (
      <div className={classNames("flex h-full gap-4 py-2 max-sd:flex-col sd:items-center", className)}>
        {enabledGameIds.map((gameId) => {
          const children = (
            <>
              <div className={classNames("max-sd:w-8 sd:w-5")}>
                <GameIcon gameId={gameId} width={32} />
              </div>
              <span className="block whitespace-nowrap max-sd:ml-3 max-sd:font-style-navigation-mobile sd:ml-2 sd:font-style-navigation-desktop">
                {gameTitles[gameId]}
              </span>
            </>
          );
          const isSsr = isSsrPage({ pathname: `/${gameId}`, environment: environment });

          if (isSsr) {
            return (
              <a
                key={gameId}
                href={`${ssrSiteUrl}/${gameId}`}
                className="flex items-center rounded border border-solid border-transparent transition-all duration-150 ease-in-out hover:border-neutral-50 max-sd:py-4 sd:h-full sd:px-2"
              >
                {children}
              </a>
            );
          }

          return (
            <Link
              key={gameId}
              to={gameId}
              className="flex items-center rounded border border-solid border-transparent transition-all duration-150 ease-in-out hover:border-neutral-50 max-sd:py-4 sd:h-full sd:px-2"
            >
              {children}
            </Link>
          );
        })}
      </div>
    );
  }

  return (
    <Listbox
      value={selectedGameId}
      onChange={(value) => {
        setSelectedGameId(value);

        const isSsr = isSsrPage({ pathname: `/${value}`, environment: environment });
        if (isSsr) {
          window.location.href = `${ssrSiteUrl}/${value}`;
        } else {
          navigate(value);
        }
      }}
    >
      <ListboxButton
        className={classNames(
          "relative flex items-center rounded bg-canvas-95 px-5 py-3 max-md:w-full max-md:justify-start md:min-w-48",
          className,
        )}
      >
        <GameIcon gameId={selectedGameId} width={20} />
        <span className="ml-3 font-style-navigation-desktop">{gameTitles[selectedGameId]}</span>
        <Icon
          icon="arrowDownNavbar"
          className="group pointer-events-none absolute right-4 top-1/2 size-2 -translate-y-1/2 text-neutral-20"
        />
      </ListboxButton>
      <ListboxOptions
        anchor="bottom"
        transition
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={classNames(
          "z-navbar w-[var(--button-width)] rounded bg-canvas-95 [--anchor-gap:0.25rem] focus:outline-none",
          "origin-top transition duration-150 ease-out data-[closed]:-translate-y-4 data-[closed]:opacity-0 data-[leave]:data-[closed]:opacity-0",
          "shadow-xl shadow-canvas-100/50",
        )}
      >
        {enabledGameIds.map((gameId) => (
          <ListboxOption
            key={gameId}
            value={gameId}
            className={classNames(
              "flex cursor-pointer items-center border border-solid border-transparent bg-canvas-95 px-5 py-3 data-[selected]:hidden data-[focus]:bg-canvas-90",
              "transition-all",
            )}
          >
            <GameIcon gameId={gameId} width={20} />
            <span className="ml-3 font-style-navigation-desktop">{gameTitles[gameId]}</span>
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};
