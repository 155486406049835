import axios from "axios";
import { dataBucketBaseURL } from "../../config";
import { loggingInterceptor } from "./interceptors/loggingInterceptor";

const blastDataBucketApi = axios.create({
  baseURL: dataBucketBaseURL,
});

blastDataBucketApi.interceptors.response.use((response) => response, loggingInterceptor);

export { blastDataBucketApi };
