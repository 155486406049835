import { z } from "zod";
import { blastAssetsApi } from "../../../apiClients/blastAssetsApi";

export const AvatarSchema = z.object({
  id: z.string(),
  name: z.string(),
  color: z.string(),
  orderIndex: z.number(),
  hidden: z.boolean().optional(),
  isUnlockOnly: z.boolean().optional(), // used as a reward
  isUnlocked: z.boolean().optional(), // user has unlocked this avatar
  rarity: z.string().optional(),
});

export const getAvatars = async () => {
  const { data } = await blastAssetsApi.get(`/avatars/index.json`);
  return z.array(AvatarSchema).parse(data);
};
