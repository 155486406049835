import styled, { css } from "styled-components";
import { ScrollAxis } from ".";
import { colors } from "../../../designSystemLegacy/colors";

export const Container = styled.div<{ axis: ScrollAxis; isScrolling: boolean }>`
  ${({ axis }) => {
    if (axis === "auto") {
      return `
                overflow: overlay;
                @supports (-moz-appearance:none) {
                    overflow: auto;
                    scrollbar-width: none;
                }
                `;
    } else if (axis === "x") {
      return `
                overflow-x: overlay;
                @supports (-moz-appearance:none) {
                    overflow-x: auto;
                    scrollbar-width: none;
                }
                `;
    } else if (axis === "y") {
      return `
                overflow-y: overlay;
                @supports (-moz-appearance:none) {
                    overflow-y: auto;
                    scrollbar-width: none;
                }
            `;
    }
  }}

  ${({ isScrolling }) => {
    if (isScrolling) {
      return ScrollingStyles;
    }

    return NotScrollingStyles;
  }}
`;

const ScrollingStyles = css`
  --scrollbar-color: ${colors.white}33;
  --background-color: transparent;

  -ms-overflow-style: none; /* IE 10+ */

  ::-webkit-scrollbar {
    background-color: var(--background-color);
    width: 0.3rem;
    height: 0.25rem;
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: var(--background-color);
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 1rem;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }

  // Disable on firefox
  @supports (-moz-appearance: none) {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }
`;

const NotScrollingStyles = css`
  ::-webkit-scrollbar {
    display: none;
  }
`;
