import { z } from "zod";

const PointsCollectionSchema = z.object({
  type: z.enum(["avatars", "emojis"]),
  context: z.literal("blast"),
  collectionId: z.string(),
  silverRarity: z.array(z.string()),
  goldRarity: z.array(z.string()),
  diamondRarity: z.array(z.string()),
  name: z.string(),
});

export type PointsCollection = z.infer<typeof PointsCollectionSchema>;

export const PointsCollectionsResponseSchema = z.object({
  collections: PointsCollectionSchema.array(),
});
