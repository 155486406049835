import { NavbarParentItem } from "./types";

export const hasActiveChildItem = (item: NavbarParentItem, pathname: string): boolean => {
  if (!item.children) return false;

  return item.children.some((child) => {
    if (child._type === "groupedNavbarItems") {
      return child.children.some((groupedChild) => groupedChild.route === pathname);
    }

    return child.route === pathname;
  });
};
