import axios, { AxiosInstance } from "axios";
import { apiBaseURL, dataBucketBaseURL } from "../../config";
import { authInterceptor } from "../../auth/authInterceptor";
import { ChatApiClient } from "../ChatApiClient";
import { PickemsApiClient } from "../PickemsApiClient";
import { PointsApiClient } from "../PointsApiClient";
import { TriggersApiClient } from "../TriggersApiClient";
import { AuthApiClient } from "../auth/AuthApiClient";
import { UsersApiClient } from "../UsersApiClient";
import { VideoApiClient } from "../VideoApiClient";
import { IngestionApiClient } from "../IngestionApiClient";
import { TournamentsDataBucketApiClient } from "../TournamentsDataBucketApiClient";
import { FantasyDataBucketApiClient } from "../FantasyDataBucketApiClient";
import { LeaderboardDataBucketApiClient } from "../LeaderboardDataBucketApiClient";
import { loggingInterceptor } from "../apiClients/interceptors/loggingInterceptor";

export class ApiService {
  protected readonly blasttvApiAxiosInstance: AxiosInstance;
  protected readonly blasttvDataBucketAxiosInstance: AxiosInstance;

  public readonly auth: AuthApiClient;
  public readonly chat: ChatApiClient;
  public readonly fantasyDataBucket: FantasyDataBucketApiClient;
  public readonly ingestion: IngestionApiClient;
  public readonly pickems: PickemsApiClient;
  public readonly points: PointsApiClient;
  public readonly tournamentsDataBucket: TournamentsDataBucketApiClient;
  public readonly triggers: TriggersApiClient;
  public readonly users: UsersApiClient;
  public readonly video: VideoApiClient;
  public readonly leaderboardDataBucket: LeaderboardDataBucketApiClient;

  constructor() {
    this.blasttvApiAxiosInstance = axios.create({
      baseURL: apiBaseURL,
    });
    this.blasttvApiAxiosInstance.interceptors.request.use(authInterceptor);
    this.blasttvApiAxiosInstance.interceptors.response.use((response) => response, loggingInterceptor);

    this.blasttvDataBucketAxiosInstance = axios.create({
      baseURL: dataBucketBaseURL,
    });
    this.blasttvDataBucketAxiosInstance.interceptors.response.use((response) => response, loggingInterceptor);

    this.auth = new AuthApiClient(this.blasttvApiAxiosInstance);
    this.chat = new ChatApiClient(this.blasttvApiAxiosInstance, this.blasttvDataBucketAxiosInstance);
    this.fantasyDataBucket = new FantasyDataBucketApiClient(this.blasttvDataBucketAxiosInstance);
    this.ingestion = new IngestionApiClient(this.blasttvApiAxiosInstance);
    this.pickems = new PickemsApiClient(this.blasttvApiAxiosInstance);
    this.points = new PointsApiClient(this.blasttvApiAxiosInstance);
    this.triggers = new TriggersApiClient(this.blasttvApiAxiosInstance);
    this.users = new UsersApiClient(this.blasttvApiAxiosInstance);
    this.video = new VideoApiClient(this.blasttvApiAxiosInstance);

    // data bucket apis
    this.tournamentsDataBucket = new TournamentsDataBucketApiClient(this.blasttvDataBucketAxiosInstance);
    this.leaderboardDataBucket = new LeaderboardDataBucketApiClient(this.blasttvDataBucketAxiosInstance);
  }
}
