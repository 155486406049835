import { ssrRoutesPatterns } from "./ssrRoutesPatterns";

export const isSsrPage = ({
  pathname,
  environment = "development",
}: {
  pathname?: string | null;
  environment?: "development" | "production" | "local";
}) => {
  if (!pathname) {
    return false;
  }
  const routesPatterns = ssrRoutesPatterns[environment];

  return routesPatterns.some((pattern) => {
    const regex = new RegExp(`^${pattern.replace("*", ".*")}$`);

    return regex.test(pathname);
  });
};
