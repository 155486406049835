import { z } from "zod";
import { ImageSchema } from "../../../api/data/sanity/shared/schemas/ImageSchema";

const MatchSchema = z.object({
  _id: z.string().uuid(),
  name: z.string(),
  teamA: z.object({
    _id: z.string().uuid(),
    name: z.string(),
    imageUrl: z.string(),
  }),
  teamB: z.object({
    _id: z.string().uuid(),
    name: z.string(),
    imageUrl: z.string(),
  }),
  teamAScore: z.number(),
  teamBScore: z.number(),
  startTime: z.string(),
});

export type Match = z.infer<typeof MatchSchema>;

const teamSchema = z.object({
  _id: z.string().uuid(),
  name: z.string(),
  imageUrl: z.string(),
  matches: z.array(MatchSchema),
});

export type Team = z.infer<typeof teamSchema>;

export const PlayerCategorySchema = z.union([
  z.literal("diamond"),
  z.literal("gold"),
  z.literal("silver"),
  z.literal("bronze"),
]);

export type PlayerCategory = z.infer<typeof PlayerCategorySchema>;

const playerSchema = z.object({
  _id: z.string().uuid(),
  firstName: z.string(),
  lastName: z.string(),
  nickname: z.string(),
  category: PlayerCategorySchema,
  image: ImageSchema.nullable(),
  team: teamSchema,
});

export type Player = z.infer<typeof playerSchema>;

const schema = z
  .object({
    title: z.string(),
    headerImage: ImageSchema,
    countdownText: z.string().nullable(),
    uid: z.string(),
    endTime: z.string(),
    diamondPlayers: z.array(playerSchema).optional().default([]),
    goldPlayers: z.array(playerSchema).optional().default([]),
    silverPlayers: z.array(playerSchema).optional().default([]),
    bronzePlayers: z.array(playerSchema).optional().default([]),
    sponsorBanner: z.any(),
    rewards: z.object({
      name: z.string(),
      cards: z.any(),
    }),
  })
  .nullable();

export type PlayerPickemsData = z.infer<typeof schema>;

export { schema };
