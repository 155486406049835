import { useEffect, useMemo, useState } from "react";
import sortBy from "lodash/sortBy";
import { Tooltip } from "../Tooltip";
import { ProfileAvatar } from "./types/ProfileAvatar";
import classNames from "classnames";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { generateImageUrl } from "../../../api/data/assets/helpers/generateImageUrl";
import { generateAssetsId } from "../../../api/data/assets/helpers/generateAssetsId";

const AvatarWrapper = ({
  id,
  name,
  isLocked = false,
  isChosen,
  areOthersChosen,
  onClick,
}: {
  id: string;
  name: string;
  isLocked?: boolean;
  isChosen: boolean;
  areOthersChosen: boolean;
  onClick: (id: string) => void;
}) => {
  const [avatarHovered, setAvatarHovered] = useState<string | null>(null);

  return (
    <div
      className="relative flex place-content-center items-center p-4"
      id={id}
      onScroll={() => {
        if (avatarHovered) {
          setAvatarHovered(null);
        }
      }}
    >
      <Tooltip content={isLocked ? `${name} (Unlocked as a Reward)` : name} isTooltipOpen={avatarHovered === id}>
        <div
          className={classNames("relative flex place-content-center items-center rounded-small", {
            "brightness-90": areOthersChosen && !isLocked,
            "brightness-[0.40]": isLocked,
            "bg-[lightgray]/20": isChosen,
          })}
          onMouseEnter={() => setAvatarHovered(id)}
          onMouseLeave={() => setAvatarHovered(null)}
        >
          <img
            src={generateImageUrl("avatars", generateAssetsId(id, "3d"), {
              height: "600",
              format: "auto",
            })}
            alt={name}
            className={classNames("size-full", {
              "cursor-pointer": !isLocked,
            })}
            onClick={isLocked ? undefined : () => onClick(id)}
          />
          {isLocked ? <LockClosedIcon className="absolute bottom-2.5 right-0 z-drawer size-4" /> : null}
        </div>
      </Tooltip>
    </div>
  );
};

function ProfileAvatars({
  avatars,
  userAvatarIds,
  onAvatarClick,
  chosenAvatar,
  isLoading = false,
}: {
  avatars?: ProfileAvatar[];
  userAvatarIds?: string[];
  onAvatarClick: (avatarId: string) => void;
  chosenAvatar?: string | null;
  isLoading?: boolean;
}) {
  const unlockedAvatars = useMemo(
    () => sortBy(avatars, ["orderIndex"]).filter((avatar) => userAvatarIds?.includes(avatar.id) && avatar.isUnlockOnly),
    [avatars, userAvatarIds],
  );

  const publicAndLockedAvatars = useMemo(
    () => sortBy(avatars, ["orderIndex"]).filter((avatar) => !avatar.hidden && !userAvatarIds?.includes(avatar.id)),
    [avatars, userAvatarIds],
  );

  useEffect(() => {
    // If there is an avatar chosen, scroll to it
    if (chosenAvatar) {
      const avatarElement = document.getElementById(chosenAvatar);
      if (avatarElement) {
        avatarElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="relative h-full overflow-x-hidden overflow-y-scroll bg-canvas-90">
        <div className="grid grid-cols-3 md:grid-cols-6">
          {Array.from({ length: 12 }).map((_, index) => (
            <div className="size-12 animate-pulse rounded-full" key={index} />
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className="relative h-full overflow-x-hidden overflow-y-scroll bg-canvas-90">
      <div className="grid grid-cols-3 md:grid-cols-6">
        {publicAndLockedAvatars.map((avatar, index) => (
          <AvatarWrapper
            key={`${avatar.id}-${index}`}
            id={avatar.id}
            name={avatar.name}
            isLocked={avatar.isUnlockOnly}
            isChosen={chosenAvatar === avatar.id}
            areOthersChosen={!!chosenAvatar && chosenAvatar !== avatar.id}
            onClick={onAvatarClick}
          />
        ))}
      </div>

      {unlockedAvatars.length > 0 && (
        <>
          <div className="relative flex place-content-center items-center">
            <div className="absolute inset-0 flex place-content-center items-center" aria-hidden="true">
              <div className="w-full border-x-0 border-b-0 border-t border-neutral-50" />
            </div>
            <div className="relative flex w-fit justify-center bg-canvas-90 px-4">
              <span className="font-style-label-2">Rewards</span>
            </div>
          </div>
          <div className="grid grid-cols-3 md:grid-cols-6">
            {unlockedAvatars.map((avatar, index) => (
              <AvatarWrapper
                key={`${avatar.id}-${index}`}
                id={avatar.id}
                name={avatar.name}
                isLocked={false}
                isChosen={chosenAvatar === avatar.id}
                areOthersChosen={!!chosenAvatar && chosenAvatar !== avatar.id}
                onClick={onAvatarClick}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export { ProfileAvatars };
