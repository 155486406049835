import { css } from "styled-components";

// TODO speak to UX/Design about our standard text styles and define them here

export const fontTitle1 = css`
  font-family: "RadionA";
  font-size: 88px;
  line-height: 100%;
  text-transform: uppercase;
`;

export const fontTitle2 = css`
  font-family: "RadionA";
  font-size: 64px;
  line-height: 100%;
  text-transform: uppercase;
`;

export const fontTitle3 = css`
  font-family: "RadionA";
  font-size: 40px;
  line-height: 100%;
  text-transform: uppercase;
`;

export const fontTitle4 = css`
  font-family: "RadionA";
  font-size: 28px;
  line-height: 100%;
  text-transform: uppercase;
`;

export const fontTitle5 = css`
  font-family: "RadionA";
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
`;

export const fontLabel = css`
  font-family: "TT Norms Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const fontBody = css`
  font-family: "TT Norms Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
`;
