import { Modal } from "@blastorg/portal-pattern-library";
import { Button } from "@blastorg/portal-pattern-library";
import backgroundImage from "./bg.jpg";
import { generateImageUrl } from "../../../api/data/assets/helpers/generateImageUrl";
import { generateAssetsId } from "../../../api/data/assets/helpers/generateAssetsId";
import { ProfileAvatars } from "../../../shared/components/ProfileAvatars";
import { z } from "zod";
import { AvatarSchema } from "../../../api/data/assets/getAvatars";

interface AvatarModalProviderProps {
  handleClose: () => void;
  isOpen: boolean;
  newAvatarId?: string;
  setNewAvatarId: (avatarId: string) => void;
  userAvatarId?: string;
  userUnlockedAvatarIds?: string[];
  isLoading: boolean;
  avatars?: z.infer<typeof AvatarSchema>[];
  saveIsDisabled?: boolean;
  isUpdating?: boolean;
  onConfirm?: () => void;
}

export function AvatarModalContent({
  handleClose,
  isOpen,
  userAvatarId,
  newAvatarId,
  setNewAvatarId,
  userUnlockedAvatarIds,
  isLoading,
  avatars,
  saveIsDisabled,
  isUpdating,
  onConfirm,
}: AvatarModalProviderProps) {
  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="shadow-none">
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
        className="flex max-h-[40rem] w-full max-w-[50rem] flex-col place-items-center gap-4 rounded bg-canvas-90 bg-cover bg-no-repeat p-5 md:max-h-[43rem] md:p-10"
      >
        <span className="font-style-desktop-h3">Change Avatar</span>
        <div className="flex place-content-center items-center rounded-full bg-neutral-20/20">
          <img
            className="h-32 md:h-64"
            src={
              userAvatarId &&
              generateImageUrl("avatars", generateAssetsId(newAvatarId ? newAvatarId : userAvatarId, "3d"), {
                height: "600",
                format: "auto",
              })
            }
          />
        </div>
        <ProfileAvatars
          isLoading={isLoading}
          avatars={avatars}
          userAvatarIds={userUnlockedAvatarIds}
          onAvatarClick={setNewAvatarId}
          chosenAvatar={newAvatarId ? newAvatarId : userAvatarId}
        />
        <div className="flex gap-5">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button isDisabled={saveIsDisabled} isLoading={isUpdating} onClick={onConfirm}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
