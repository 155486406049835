import { AxiosInstance } from "axios";
import { TriggerVote, TriggerVoteCreateResponse } from "../../types/Triggers";

export class TriggersApiClient {
  public constructor(private readonly client: AxiosInstance) {}
  public getTriggerPollVote = async (id: string): Promise<TriggerVote> => {
    const { data } = await this.client.get(`/v1/triggers/polls/${id}/vote`);

    return data;
  };

  public postTriggerPollVote = async (id: string, optionId: string): Promise<TriggerVoteCreateResponse> => {
    const { data } = await this.client.post(`/v1/triggers/polls/${id}/vote`, {
      optionId,
    });

    return data;
  };
}
