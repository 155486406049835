import { useQueries } from "@tanstack/react-query";
import { getSeriesById } from ".";
import { queryKeys } from "../queryKeys";

export const useFetchMultipleSeries = ({ seriesIds }: { seriesIds: string[] }) => {
  return useQueries({
    queries: seriesIds.map((id: string) => {
      return {
        queryKey: queryKeys.seriesById(id),
        queryFn: () => getSeriesById(id),
        staleTime: 5000,
      };
    }),
  });
};
