import styled from "styled-components";
import { borderRadiusSmall } from "../../../../../styles/borders";
import { colors } from "../../../../../designSystemLegacy/colors";
import { desktopB3Body, mobileE3Eyebrow } from "../../../../../designSystemLegacy/typography/Text/styled";

export const StyledInput = styled.input`
  ${desktopB3Body};
  ${borderRadiusSmall};
  padding: 0.75rem;
  outline: none;
  background-color: rgba(46, 45, 45, 0.5);
  transition: all 0.25s ease;
  border: 1px solid ${colors.white}80;
  color: ${colors.white};
  margin-bottom: 1rem;

  &:invalid:not(:placeholder-shown) {
    border-color: ${colors.blastLiveRed};
  }

  &:focus {
    border-color: ${colors.white}99;

    &::placeholder {
      color: ${colors.white}99;
    }
  }

  &::placeholder {
    transition: all 0.25s ease;
    color: ${colors.white}80;
  }
`;

export const PasswordShowHideButton = styled.div`
  ${mobileE3Eyebrow};
  padding: 1rem 0.5rem;
  outline: none;
  border: none;
  background: transparent;
  transition: all 0.25s ease;
  color: ${colors.white};
  position: absolute;
  top: 0;
  right: 0.75rem;
  width: fit-content;
  color: ${colors.white}99;
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;

  ${PasswordShowHideButton} {
    transition: all 0.25s ease;
    opacity: 0;
  }

  &:hover {
    ${PasswordShowHideButton} {
      opacity: 1;
    }
  }

  ${StyledInput} {
    width: 100%;
  }
`;
