import { useMemo } from "react";
import { Broadcast } from "../../../../api/data/broadcasts/getLiveBroadcasts";
import { getSeriesState } from "../../../helpers/getSeriesState";
import { useFetchMultipleSeries } from "../../../../api/data/tournament/getSeriesById/useFetchMultipleSeries";

export const useGetNavbarBroadcastSeries = ({ liveBroadcasts }: { liveBroadcasts: Broadcast[] | undefined }) => {
  const seriesIds = useMemo(() => {
    if (!liveBroadcasts) return [];

    return (
      (liveBroadcasts
        .map((broadcast) => broadcast.seriesIds)
        .flat()
        .filter((id) => id) as string[]) || []
    );
  }, [liveBroadcasts]);

  const seriesQueries = useFetchMultipleSeries({
    seriesIds,
  });

  const sortedSeries = useMemo(() => {
    if (!seriesQueries) return [];
    const sorted = seriesQueries.sort((a, b) => {
      if (!a.data || !b.data) return 0;
      if (!a.data.timeOfSeries || !b.data.timeOfSeries) return 0;
      return a.data.timeOfSeries.getTime() - b.data.timeOfSeries.getTime();
    });
    return sorted;
  }, [seriesQueries]);

  return useMemo(() => {
    if (!liveBroadcasts) return [];
    const broadcastSeries: Record<
      string,
      {
        slug: string;
        title: string;
        id: string;
        state: "pre" | "live" | "post";
      }
    > = {};

    for (const series of sortedSeries) {
      if (series.data) {
        const state = getSeriesState({ series: series.data });
        if (state === "post") continue;
        if (!state) continue;
        const broadcast = liveBroadcasts.find(
          (broadcast) => broadcast.seriesIds && broadcast.seriesIds.includes(series.data.uuid),
        );
        if (!broadcast) continue;
        if (!broadcastSeries[broadcast.slug]) {
          broadcastSeries[broadcast.slug] = {
            slug: broadcast.slug,
            title: `${series.data.teamA?.shorthand} vs ${series.data.teamB?.shorthand}`,
            id: broadcast.id,
            state,
          };
          continue;
        }
        if (state === "live") {
          broadcastSeries[broadcast.slug] = {
            slug: broadcast.slug,
            title: `${series.data.teamA?.shorthand} vs ${series.data.teamB?.shorthand}`,
            id: broadcast.id,
            state,
          };
          continue;
        }
      }
    }

    return broadcastSeries;
  }, [sortedSeries, liveBroadcasts]);
};
