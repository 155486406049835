import { ReactNode } from "react";
import { Color } from "../../colors";
import { HeadingTypography, StyledHeading } from "./styled";

export type Headings = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface HeadingProps {
  asTag?: Headings;
  typography?: HeadingTypography;
  color?: Color;
  children?: ReactNode;
  className?: string;
}

function Heading({ children, typography = "desktopH5", asTag = "h2", color, className }: HeadingProps) {
  return (
    <StyledHeading as={asTag} typography={typography} $color={color} className={className}>
      {children}
    </StyledHeading>
  );
}

export { Heading };
