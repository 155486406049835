import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { rudderstack } from "../../rudderstack";

export type AuthModalState =
  | "login"
  | "register"
  | "awaiting-verification-email"
  | "verification-email-resent"
  | "forgot-password"
  | "reset-password-requested"
  | "first-login"
  | "ban";

interface AuthModalProviderValue {
  isModalOpen: boolean;
  openModal: (state?: AuthModalState) => void;
  modalState: AuthModalState;
  closeModal: () => void;
}

const AuthModalContext = createContext<AuthModalProviderValue | null>(null);

function AuthModalProvider({ children }: { children?: React.ReactNode }) {
  // Get modal state from URL query params - if modal=login or modal=register
  // intended as a quick workaround for modals opened from the Remix site
  const modalParam = new URLSearchParams(window.location.search).get("modal");
  const modalStateFromParam: AuthModalState | undefined =
    modalParam === "login" ? "login" : modalParam === "register" ? "register" : undefined;

  const [isModalOpen, setIsModalOpen] = useState(modalStateFromParam ? true : false);
  const [modalState, setModalState] = useState<AuthModalState>(modalStateFromParam ?? "login");

  const openModal = useCallback((state: AuthModalState = "login") => {
    if (state === "register") {
      rudderstack?.track("Register Button Clicked");
    }
    if (state === "first-login") {
      rudderstack?.track("User Email Verified");
    }
    setModalState(state);
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const providerValue = useMemo(() => {
    return {
      isModalOpen,
      openModal,
      closeModal,
      modalState,
    };
  }, [closeModal, isModalOpen, modalState, openModal]);

  return <AuthModalContext.Provider value={providerValue}>{children}</AuthModalContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export const useAuthModal = () => {
  const authModal = useContext(AuthModalContext);
  if (!authModal) {
    throw new Error("useAuthModal must be used within AuthModalProvider");
  }
  return authModal;
};
export { AuthModalProvider };
