import { captureException } from "@sentry/browser";

const PREFIX = "blasttv:";

export class StorageService<T> {
  constructor(private readonly storage: Storage) {}

  set<K extends keyof T>(key: K, value: T[K]): void {
    try {
      this.storage.setItem(PREFIX + key.toString(), JSON.stringify(value));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error setting item ${PREFIX + key.toString()}: ${e}`);
      captureException(e);
    }
  }

  get<K extends keyof T>(key: K): T[K] | null {
    try {
      const value = this.storage.getItem(PREFIX + key.toString());

      if (value === null || value === "null" || value === "undefined") {
        return null;
      }

      return JSON.parse(value);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error getting item ${PREFIX + key.toString()}: ${e}`);
      captureException(e);
      return null;
    }
  }
}
